type ErrorWithMessage = {
  message: string;
};

const isErrorWithMessage = function isErrorWithMessage(
  error: unknown,
): error is ErrorWithMessage {
  return (
    typeof error === "object" &&
    error !== null &&
    "message" in error &&
    typeof error.message === "string"
  );
};

const toErrorWithMessage = function toErrorWithMessage(
  maybeError: unknown,
): ErrorWithMessage {
  if (isErrorWithMessage(maybeError)) return maybeError;

  try {
    return new Error(JSON.stringify(maybeError));
  } catch {
    // fallback in case there's an error stringifying the maybeError
    // like with circular references for example.
    return new Error(String(maybeError));
  }
};

export function getErrorMessage(error: unknown): string {
  return toErrorWithMessage(error).message;
}

export function reportError({ message }: { message: string }): void {
  console.error(message);
}
