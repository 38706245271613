import { getWindowOrigin } from "@app/helpers/utils";
import useSWR, { SWRResponse } from "swr";

/**
 * Custom hook for making API requests using SWR. This hook is used to fetch data from the API. i.e GET requests.
 * @param endpoint - The API endpoint to fetch data from based on your api function path.
 * @param T - Accepts type of what is expected to be returned from the API.
 */

type SWRResponseProps = {
  endpoint: string;
  refreshInterval?: number;
  invoke?: boolean;
};

export default function useSwrApi<T>({
  endpoint,
  refreshInterval,
  invoke = true,
}: SWRResponseProps): SWRResponse<T, Error> {
  const url = `${getWindowOrigin()}/_plat/api/v1/${endpoint}`;
  const swrRes: SWRResponse<T, Error> = useSWR<T, Error>(
    invoke ? url : null,
    fetcher,
    {
      refreshInterval,
    },
  );
  return swrRes;
}

async function fetcher(url: string) {
  const res = await fetch(url);
  if (!res.ok) {
    throw new Error("An error occurred while fetching the data.");
  }
  return res.json();
}
