import { AUTHOR_IMAGE } from "@app/config/imageresponsive";
import InternalLink from "@elements/InternalLink/InternalLink";
import { Image } from "@elements/ResponsiveImage/Image";
import IcArrowRight from "@icons/arrow-right.svg";
import IcEmail from "@icons/email.svg";
import type { ArticleBylineBasic } from "@typings/OpenSearch";
import type { ReactElement } from "react";
import { Link } from "react-router-dom";

export type AuthorCardProps = {
  author: ArticleBylineBasic;
  showLink: boolean;
};

export default function AuthorCard({
  author,
  showLink,
}: AuthorCardProps): ReactElement {
  return (
    <div className="mx-lg flex gap-x-md lg:mx-md">
      {author.photo && (
        <Image
          className="size-[112px] rounded-full object-cover"
          src={author.photo}
          alt={author.name}
          width={AUTHOR_IMAGE.width}
          height={AUTHOR_IMAGE.height}
          loading="eager"
        />
      )}
      <div className="flex flex-col gap-y-md">
        <h1 className="text-24 font-semibold leading-1.2">{author.name}</h1>
        {author.designation && (
          <span className="text-18 font-semibold leading-1.2">
            {author.designation}
          </span>
        )}
        {author.email && (
          <Link
            className="flex items-center gap-x-xs text-16 font-medium leading-1.5 text-primary-500 hover:underline"
            to={`mailto:${author.email}`}
          >
            {author.email}
            <IcEmail className="size-icon-md" />
          </Link>
        )}
        {showLink && (
          <InternalLink
            className="inline text-16 font-medium leading-1.5 text-primary-500 hover:underline"
            to={author.urlPath}
          >
            {`Read articles by ${author.name}`}
            <IcArrowRight className="ml-xs inline size-icon-md" />
          </InternalLink>
        )}
      </div>
    </div>
  );
}
