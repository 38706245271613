import { enUS } from "date-fns/locale";
import { formatInTimeZone } from "date-fns-tz";

const timeZone = "Asia/Singapore";

export function formatDate(
  dateString: string,
  dateFormat?: string,
  setLocale?: boolean,
): string {
  const format = dateFormat ?? "MMM d, yyyy";
  const date = new Date(dateString);
  if (setLocale) {
    return formatInTimeZone(date, timeZone, format, { locale: enUS });
  } else {
    return formatInTimeZone(date, timeZone, format);
  }
}

export const getCurrentDate = (formatStr: string = "yyyyMMdd") => {
  const currentDate = new Date();
  return formatInTimeZone(currentDate, timeZone, formatStr);
};
