import { Referers } from "@app/config/gadata";
import type { ArticleType } from "@typings/OpenSearch";

// "n/a" is used when orientation does not matter in the situations below:
// - For `orientation.group`, when `count` is 1 (there is only one `ArticleCard` in the group)
// - For `orientation.item`, when `showImage` is false
type ArticleOrientation =
  | "vertical"
  | "vertical-reverse"
  | "horizontal"
  | "horizontal-reverse"
  | "n/a";
type ArticleGroupOrientation = Extract<
  ArticleOrientation,
  "vertical" | "horizontal" | "n/a"
>;

export type ArticleGroupLayoutVariant =
  | "home-skybox"
  | "home-top-left"
  | "home-top-right"
  | "home-bottom-left-1"
  | "home-bottom-left-2"
  | "home-bottom-left-3"
  | "home-bottom-right"
  | "category-top"
  | "category-bottom"
  | "search"
  | "article-top-stories"
  | "article-related-single"
  | "article-related-multiple-left"
  | "article-related-multiple-right";

export type ArticleCategory = "news" | "entertainment" | "lifestyle" | "sports";

export interface ArticleGroupLayout {
  count: number;
  orientation: {
    group: {
      sm: ArticleGroupOrientation;
      md?: ArticleGroupOrientation;
      lg: ArticleGroupOrientation;
    };
    item: {
      sm: ArticleOrientation;
      md?: ArticleOrientation;
      lg: ArticleOrientation;
    };
  };
  fontSize: {
    sm: string;
    lg: string;
  };
  showImage: boolean;
  imageWidth: {
    sm: number;
    lg: number;
  };
  imageHeight: {
    sm: number;
    lg: number;
  };
  referer: string;
  headingLevel?: Extract<keyof JSX.IntrinsicElements, "h1" | "h2" | "h3">;
}

export const ARTICLE_GROUP_LAYOUT: Record<
  ArticleGroupLayoutVariant,
  ArticleGroupLayout
> = {
  ["home-skybox"]: {
    count: 4,
    orientation: {
      group: {
        sm: "horizontal",
        lg: "horizontal",
      },
      item: {
        sm: "n/a",
        lg: "n/a",
      },
    },
    fontSize: {
      sm: "text-18",
      lg: "text-18",
    },
    showImage: false,
    headingLevel: "h3",
    imageWidth: {
      sm: 0,
      lg: 0,
    },
    imageHeight: {
      sm: 0,
      lg: 0,
    },
    referer: Referers.HOME_TOPREADS,
  },
  ["home-top-left"]: {
    count: 1,
    orientation: {
      group: {
        sm: "n/a",
        lg: "n/a",
      },
      item: {
        sm: "vertical-reverse",
        lg: "vertical-reverse",
      },
    },
    fontSize: {
      sm: "text-30",
      lg: "text-54",
    },
    showImage: true,
    headingLevel: "h1",
    imageWidth: {
      sm: 374,
      lg: 653,
    },
    imageHeight: {
      sm: 281,
      lg: 490,
    },
    referer: Referers.HOME_TOP_MAIN,
  },
  ["home-top-right"]: {
    count: 5,
    orientation: {
      group: {
        sm: "vertical",
        lg: "vertical",
      },
      item: {
        sm: "n/a",
        lg: "n/a",
      },
    },
    fontSize: {
      sm: "text-24",
      lg: "text-26",
    },
    showImage: false,
    headingLevel: "h2",
    imageWidth: {
      sm: 0,
      lg: 0,
    },
    imageHeight: {
      sm: 0,
      lg: 0,
    },
    referer: Referers.HOME_TOP_LIST,
  },
  ["home-bottom-left-1"]: {
    count: 1,
    orientation: {
      group: {
        sm: "n/a",
        lg: "n/a",
      },
      item: {
        sm: "vertical-reverse",
        lg: "vertical-reverse",
      },
    },
    fontSize: {
      sm: "text-30",
      lg: "text-54",
    },
    showImage: true,
    headingLevel: "h2",
    imageWidth: {
      sm: 374,
      lg: 474,
    },
    imageHeight: {
      sm: 281,
      lg: 356,
    },
    referer: Referers.NONE,
  },
  ["home-bottom-left-2"]: {
    count: 2,
    orientation: {
      group: {
        sm: "horizontal",
        lg: "horizontal",
      },
      item: {
        sm: "vertical",
        lg: "vertical",
      },
    },
    fontSize: {
      sm: "text-18",
      lg: "text-20",
    },
    showImage: true,
    headingLevel: "h2",
    imageWidth: {
      sm: 179,
      lg: 229,
    },
    imageHeight: {
      sm: 134,
      lg: 172,
    },
    referer: Referers.NONE,
  },
  ["home-bottom-left-3"]: {
    count: 2,
    orientation: {
      group: {
        sm: "vertical",
        lg: "vertical",
      },
      item: {
        sm: "n/a",
        lg: "n/a",
      },
    },
    fontSize: {
      sm: "text-24",
      lg: "text-26",
    },
    showImage: false,
    headingLevel: "h2",
    imageWidth: {
      sm: 0,
      lg: 0,
    },
    imageHeight: {
      sm: 0,
      lg: 0,
    },
    referer: Referers.NONE,
  },
  ["home-bottom-right"]: {
    count: 3,
    orientation: {
      group: {
        sm: "vertical",
        lg: "vertical",
      },
      item: {
        sm: "vertical",
        lg: "vertical",
      },
    },
    fontSize: {
      sm: "text-24",
      lg: "text-26",
    },
    showImage: true,
    headingLevel: "h2",
    imageWidth: {
      sm: 374,
      lg: 323,
    },
    imageHeight: {
      sm: 281,
      lg: 243,
    },
    referer: Referers.NONE,
  },
  ["category-top"]: {
    count: 3,
    orientation: {
      group: {
        sm: "vertical",
        md: "horizontal",
        lg: "horizontal",
      },
      item: {
        sm: "vertical",
        lg: "vertical",
      },
    },
    fontSize: {
      sm: "text-24",
      lg: "text-26",
    },
    showImage: true,
    headingLevel: "h2",
    imageWidth: {
      sm: 374,
      lg: 370,
    },
    imageHeight: {
      sm: 281,
      lg: 277,
    },
    referer: Referers.CATEGORY_TOP,
  },
  ["category-bottom"]: {
    count: 5,
    orientation: {
      group: {
        sm: "vertical",
        lg: "vertical",
      },
      item: {
        sm: "horizontal-reverse",
        lg: "horizontal-reverse",
      },
    },
    fontSize: {
      sm: "text-18",
      lg: "text-26",
    },
    showImage: true,
    headingLevel: "h2",
    imageWidth: {
      sm: 167,
      lg: 287,
    },
    imageHeight: {
      sm: 126,
      lg: 216,
    },
    referer: Referers.CATEGORY_LIST,
  },
  ["search"]: {
    count: 10,
    orientation: {
      group: {
        sm: "vertical",
        lg: "vertical",
      },
      item: {
        sm: "horizontal-reverse",
        lg: "horizontal-reverse",
      },
    },
    fontSize: {
      sm: "text-18",
      lg: "text-26",
    },
    showImage: true,
    headingLevel: "h2",
    imageWidth: {
      sm: 167,
      lg: 167,
    },
    imageHeight: {
      sm: 126,
      lg: 126,
    },
    referer: Referers.SEARCH,
  },
  ["article-top-stories"]: {
    count: 5,
    orientation: {
      group: {
        sm: "vertical",
        lg: "vertical",
      },
      item: {
        sm: "horizontal",
        lg: "horizontal",
      },
    },
    fontSize: {
      sm: "text-16",
      lg: "text-16",
    },
    showImage: true,
    headingLevel: "h2",
    imageWidth: {
      sm: 150,
      lg: 150,
    },
    imageHeight: {
      sm: 113,
      lg: 123,
    },
    referer: Referers.TOPSTORIES_WEEK,
  },
  ["article-related-single"]: {
    count: 1,
    orientation: {
      group: {
        sm: "n/a",
        lg: "n/a",
      },
      item: {
        sm: "horizontal",
        lg: "horizontal",
      },
    },
    fontSize: {
      sm: "text-16",
      lg: "text-18",
    },
    showImage: true,
    headingLevel: "h2",
    imageWidth: {
      sm: 150,
      lg: 150,
    },
    imageHeight: {
      sm: 113,
      lg: 123,
    },
    referer: Referers.TOPSTORIES_INLINE,
  },
  ["article-related-multiple-left"]: {
    count: 1,
    orientation: {
      group: {
        sm: "n/a",
        lg: "n/a",
      },
      item: {
        sm: "vertical",
        lg: "vertical",
      },
    },
    fontSize: {
      sm: "text-24",
      lg: "text-26",
    },
    showImage: true,
    headingLevel: "h2",
    imageWidth: {
      sm: 374,
      lg: 318,
    },
    imageHeight: {
      sm: 281,
      lg: 239,
    },
    referer: Referers.TOPSTORIES_BOTTOM,
  },
  ["article-related-multiple-right"]: {
    count: 3,
    orientation: {
      group: {
        sm: "vertical",
        lg: "vertical",
      },
      item: {
        sm: "n/a",
        lg: "n/a",
      },
    },
    fontSize: {
      sm: "text-16",
      lg: "text-18",
    },
    showImage: false,
    headingLevel: "h2",
    imageWidth: {
      sm: 0,
      lg: 0,
    },
    imageHeight: {
      sm: 0,
      lg: 0,
    },
    referer: Referers.TOPSTORIES_BOTTOM,
  },
};

export const ARTICLE_COUNT: Record<string, { [key: string]: number }> = {
  home: {
    top:
      ARTICLE_GROUP_LAYOUT["home-top-left"].count +
      ARTICLE_GROUP_LAYOUT["home-top-right"].count,
    mostTalkedAbout:
      ARTICLE_GROUP_LAYOUT["home-bottom-left-1"].count +
      ARTICLE_GROUP_LAYOUT["home-bottom-left-2"].count +
      ARTICLE_GROUP_LAYOUT["home-bottom-left-3"].count +
      ARTICLE_GROUP_LAYOUT["home-bottom-right"].count,
    topReads: ARTICLE_GROUP_LAYOUT["home-skybox"].count,
  },
  // Shared between `section`, `tag`, `author` pages
  category: {
    initial:
      ARTICLE_GROUP_LAYOUT["category-top"].count +
      ARTICLE_GROUP_LAYOUT["category-bottom"].count,
    additional: 5,
  },
  search: {
    results: ARTICLE_GROUP_LAYOUT["search"].count,
  },
  article: {
    relatedStories:
      ARTICLE_GROUP_LAYOUT["article-related-single"].count +
      ARTICLE_GROUP_LAYOUT["article-related-multiple-left"].count +
      ARTICLE_GROUP_LAYOUT["article-related-multiple-right"].count,
    topReads: ARTICLE_GROUP_LAYOUT["article-top-stories"].count,
  },
};

export const ARTICLE_CATEGORY_MAP: Record<ArticleCategory, string[]> = {
  news: ["singapore", "world", "business", "views", "backstage", "others"],
  entertainment: ["movies", "music", "tv", "star-style", "school-frock"],
  lifestyle: [
    "makan",
    "hed-chef",
    "weets-eats",
    "health",
    "tech",
    "travel",
    "fashion",
    "shopping",
    "biker-boy",
    "celeb-chow",
    "others",
  ],
  sports: [
    "football",
    "singapore-football",
    "team-singapore",
    "school-sports",
    "athletics",
    "basketball",
    "cricket",
    "swimming",
    "tennis",
    "golf",
    "racing",
  ],
};

export const ARTICLE_TYPE_MAP: Record<
  ArticleType,
  {
    label: string;
    icon: string;
    color: string;
    bgColor: string;
  }
> = {
  comment: {
    label: "Comment",
    icon: "thought",
    color: "text-white",
    bgColor: "bg-primary-600",
  },
  exclusive: {
    label: "Exclusive!",
    icon: "star",
    color: "text-white",
    bgColor: "bg-tnp-red-500",
  },
  ["sponsored-content"]: {
    label: "Sponsored Content",
    icon: "note",
    color: "text-primary-500",
    bgColor: "bg-primary-100",
  },
  old: {
    label: "This article is more than 12 months old",
    icon: "calendar",
    color: "text-grayscale-800",
    bgColor: "bg-secondary-100",
  },
};
