import { IframeProps, IframeWrapperAttr } from "@elements/Iframe/Iframe";
import { isThisUrlFrom } from "@helpers/utils";

export default function IframeAttr(
  source: string,
  className?: string,
): IframeProps {
  // Brightcove embed handled in iframe
  // to avoid the conflict of multiple accountIds on the same page
  if (isThisUrlFrom(source, "brightcove")) {
    return {
      url: source,
      className: `${className} w-[620px] h-[349px]`,
      allowFullScreen: true,
    };
    // For other Facebook type (without post.php)
  } else if (isThisUrlFrom(source, "facebook")) {
    const wrapper: IframeWrapperAttr = {
      className: "flex h-screen w-full items-center justify-center",
    };

    return {
      url: source,
      className: "h-full w-full border-none max-w-[552px]",
      addWrapper: true,
      wrapperAttr: wrapper,
    };
  }

  return {
    url: source,
    className: `${className} w-[620px] h-[349px]`,
  };
}
