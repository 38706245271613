import { SOCIAL_EMBED_WIDTH } from "@app/config/embed";
import { useWindowSize } from "@app/hooks/useWindowSize/useWindowSize";
import WrapperContainer from "@elements/Embeds/helper/WrapperContainer";
import { InstagramEmbed, InstagramEmbedProps } from "react-social-media-embed";

type InstagramEmbedCustomProps = {
  url: InstagramEmbedProps["url"];
  className?: string;
};
export default function InstagramEmbedCustom({
  url,
  className = "",
}: InstagramEmbedCustomProps): React.ReactElement {
  const { isScreenMD } = useWindowSize();

  return (
    <WrapperContainer className={className}>
      <InstagramEmbed
        url={url}
        width={isScreenMD ? SOCIAL_EMBED_WIDTH : "100%"}
      />
    </WrapperContainer>
  );
}
