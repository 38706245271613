import WrapperContainer from "@elements/Embeds/helper/WrapperContainer";
import { YouTubeEmbed, YouTubeEmbedProps } from "react-social-media-embed";

type YouTubeEmbedCustomProps = {
  url: YouTubeEmbedProps["url"];
  className?: string;
  width?: string;
  height?: string;
};
export default function YouTubeEmbedCustom({
  url,
  className = "",
  width = "",
  height = "",
}: YouTubeEmbedCustomProps): React.ReactElement {
  return (
    <WrapperContainer className={className}>
      <YouTubeEmbed url={url} width={width} height={height} />
    </WrapperContainer>
  );
}
