import { REACTION_CONFIG } from "@app/config/sharing";
import type { ReactElement } from "react";

export default function ReactionIcon(): ReactElement {
  // TODO: show top 2 emoji with most reaction count?
  return (
    <div className="relative h-[20px] w-[36px]">
      <img
        className="absolute right-0 top-0 size-icon-md rounded-full bg-white"
        src={REACTION_CONFIG["emoji-laughing"].imageUrl}
        alt={REACTION_CONFIG["emoji-laughing"].imageAlt}
      />
      <img
        className="absolute left-0 top-0 size-icon-md rounded-full bg-white"
        src={REACTION_CONFIG["emoji-red-heart"].imageUrl}
        alt={REACTION_CONFIG["emoji-red-heart"].imageAlt}
      />
    </div>
  );
}
