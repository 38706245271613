import { ArticleTag, ProcessedArticle } from "@typings/OpenSearch";

export function getTagsName(tags: ArticleTag[]): string {
  return tags.map((tag) => tag.name).join(",");
}

export function getLevel2(article: ProcessedArticle): string {
  const level2 = article.section.parent.name || "";

  return level2;
}

export function getChapter1(article: ProcessedArticle): string {
  const subSectionName = article.section.name || "";

  return subSectionName;
}
