import { BUTTON_TITLES } from "@app/config/constants";
import { GALLERY_IMAGE } from "@app/config/imageresponsive";
import BrightcoveEmbedCustom from "@elements/Embeds/BrightcoveEmbedCustom";
import YouTubeEmbedCustom from "@elements/Embeds/YouTubeEmbedCustom";
import { Image, ImageCrop } from "@elements/ResponsiveImage/Image";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { isThisUrlFrom } from "@helpers/utils";
import { getWindowHref } from "@helpers/utils";
import IcChevronLeft from "@icons/chevron-left.svg";
import IcChevronRight from "@icons/chevron-right.svg";
import IcCross from "@icons/cross.svg";
import type { ArticleMedia } from "@typings/OpenSearch";
import { type ReactElement, useCallback, useEffect, useState } from "react";

import Advertisement from "../../Elements/Advertisement/Advertisement";

export type GalleryProps = {
  media: ArticleMedia[];
  onClose?(): void;
};

export default function Gallery({
  media,
  onClose,
}: GalleryProps): ReactElement | null {
  const [currentIndex, setCurrentIndex] = useState(0);
  const sendDataToGTM = useGTMDispatch();
  const sendGaEvent = useCallback(
    (action: string) => {
      sendDataToGTM({
        event: "custom_event",
        clickCategory: "article photo carousel",
        clickAction: action,
        clickLabel: getWindowHref(),
      });
    },
    [sendDataToGTM],
  );

  const handleNextClick = useCallback(() => {
    sendGaEvent("next");
    setCurrentIndex(Math.min(media.length - 1, currentIndex + 1));
  }, [sendGaEvent, currentIndex, media.length]);

  const handlePreviousClick = useCallback(() => {
    sendGaEvent("previous");
    setCurrentIndex(Math.max(0, currentIndex - 1));
  }, [sendGaEvent, currentIndex]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case "ArrowRight":
          handleNextClick();
          break;
        case "ArrowLeft":
          handlePreviousClick();
          break;
        case "Escape":
          onClose?.();
          break;
      }
    };
    globalThis.addEventListener("keydown", handleKeyDown);

    return () => {
      globalThis.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleNextClick, handlePreviousClick, onClose]);

  if (media.length === 0) {
    return null;
  }

  const currentMedia = media[currentIndex];
  const url = currentMedia.url;

  const currentMediaType = currentMedia.mimeType.includes("video")
    ? isThisUrlFrom(url, "youtube") || isThisUrlFrom(url, "youtu.be")
      ? "youtube"
      : isThisUrlFrom(url, "brightcove")
        ? "brightcove"
        : "video"
    : "image";

  return (
    <section className="fixed left-0 top-0 z-[1002] flex h-dvh w-full flex-col bg-grayscale-900 font-secondary text-white lg:bg-black/90">
      <div className="flex justify-between">
        <span className="m-md text-16 leading-1.5">{`${currentIndex + 1} / ${media.length}`}</span>
        <button
          className="p-md"
          title={BUTTON_TITLES.galleryClose}
          onClick={onClose}
        >
          <IcCross className="size-icon-md" />
        </button>
      </div>
      <div className="flex grow lg:items-center">
        <figure className="mx-auto flex w-full max-w-[680px] flex-col lg:max-h-[calc(100vh-120px)]">
          <div className="relative flex aspect-video max-h-[50dvh] justify-center">
            {currentMediaType === "image" && (
              <Image
                className="object-contain"
                src={currentMedia.caasUrl}
                alt={currentMedia.caption}
                width={GALLERY_IMAGE.width}
                height={GALLERY_IMAGE.height}
                imageCrop={ImageCrop.FITWIDTH}
                loading="lazy"
              />
            )}
            {currentMediaType === "video" && (
              // eslint-disable-next-line jsx-a11y/media-has-caption
              <video controls>
                <source
                  src={currentMedia.caasUrl}
                  type={currentMedia.mimeType}
                />
              </video>
            )}
            {currentMediaType === "brightcove" && (
              <BrightcoveEmbedCustom
                url={currentMedia.url}
                className="w-full"
              />
            )}
            {currentMediaType === "youtube" && (
              <YouTubeEmbedCustom url={currentMedia.url} className="w-full" />
            )}
            {currentIndex !== 0 && (
              <div className="absolute left-0 top-1/2 flex -translate-y-1/2 items-center">
                <button
                  className="p-md"
                  title={BUTTON_TITLES.galleryPrevious}
                  onClick={handlePreviousClick}
                >
                  <IcChevronLeft className="size-icon-lg" />
                </button>
              </div>
            )}
            {currentIndex < media.length - 1 && (
              <div className="absolute right-0 top-1/2 flex -translate-y-1/2 items-center">
                <button
                  className="p-md"
                  title={BUTTON_TITLES.galleryNext}
                  onClick={handleNextClick}
                >
                  <IcChevronRight className="size-icon-lg" />
                </button>
              </div>
            )}
          </div>

          {(currentMedia.caption || currentMedia.credit) && (
            <figcaption className="mx-md my-lg flex flex-col gap-y-md">
              {currentMedia.caption && (
                <span className="text-18 leading-1.5">
                  {currentMedia.caption}
                </span>
              )}
              {currentMedia.credit && (
                <span className="text-12 font-medium leading-1.5">
                  {currentMedia.credit}
                </span>
              )}
            </figcaption>
          )}
        </figure>
      </div>
      <div>
        <Advertisement
          variant="lb2"
          pageName="gallery"
          rootClassName="w-full pb-sm"
          isCompanionAds
        />
      </div>
    </section>
  );
}
