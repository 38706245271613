import { BUTTON_TITLES } from "@app/config/constants";
import { SHARE_BUTTON_CONFIG } from "@app/config/sharing";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import IcShare from "@icons/share.svg";
import cx from "classnames";
import { lazy, type ReactElement, Suspense } from "react";

type ShareButtonListProps = {
  variant: "partial" | "full";
  dropdownPosition: "top" | "bottom";
  placingPosition: "top" | "bottom" | "float";
  articleTitle: string;
  articleUrl: string;
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
};

export default function ShareButtonList({
  variant,
  dropdownPosition,
  placingPosition,
  articleTitle,
  articleUrl,
  isExpanded,
  setIsExpanded,
}: ShareButtonListProps): ReactElement {
  const handleCopyToClipboard = (url: string) => {
    globalThis.navigator.clipboard
      .writeText(url)
      .then(function () {})
      .catch(function (err) {
        console.error("Error copying text to clipboard: ", err);
      });
    setIsExpanded(false);
  };

  const sendDataToGTM = useGTMDispatch();
  const sendGaEvent = (socialDestination: string) => {
    sendDataToGTM({
      event: "custom_event",
      clickCategory: "sharearticle_" + placingPosition,
      clickAction: "click",
      clickLabel: socialDestination,
    });
  };

  return (
    <div className="relative flex gap-x-md">
      {variant === "full" &&
        (
          Object.keys(
            SHARE_BUTTON_CONFIG,
          ) as (keyof typeof SHARE_BUTTON_CONFIG)[]
        )
          .slice(0, 2)
          .map((key) => {
            const config = SHARE_BUTTON_CONFIG[key];
            const Icon = lazy(
              () =>
                import(`../../../assets/icons/${config.icon}.svg`) as Promise<{
                  default: React.FC<React.SVGProps<SVGElement>>;
                }>,
            );
            return (
              <a
                key={key}
                href={config.getUrl(articleUrl, articleTitle)}
                title={config.title}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  sendGaEvent(config.ga);
                }}
              >
                <Suspense fallback={<div className="size-icon-lg" />}>
                  <Icon className="size-icon-lg" />
                </Suspense>
              </a>
            );
          })}
      <button
        className={cx("transition-colors duration-200", {
          "text-black": isExpanded,
        })}
        title={
          isExpanded
            ? BUTTON_TITLES.shareCloseToggle
            : BUTTON_TITLES.shareOpenToggle
        }
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <IcShare className="size-icon-lg" />
      </button>
      {isExpanded && (
        <div
          className={cx(
            "absolute right-0 z-10 animate-fade-in rounded-lg bg-secondary-50 p-md shadow-share",
            {
              "bottom-[calc(100%+8px)]": dropdownPosition === "top",
              "top-[calc(100%+8px)]": dropdownPosition === "bottom",
            },
          )}
        >
          <ul className="flex flex-col gap-y-sm text-left font-secondary text-16 text-grayscale-800 lg:text-18">
            {(
              Object.keys(
                SHARE_BUTTON_CONFIG,
              ) as (keyof typeof SHARE_BUTTON_CONFIG)[]
            )
              .slice(variant === "partial" ? 0 : 2)
              .map((key) => {
                const config = SHARE_BUTTON_CONFIG[key];
                const Icon = lazy(
                  () =>
                    import(
                      `../../../assets/icons/${config.icon}.svg`
                    ) as Promise<{
                      default: React.FC<React.SVGProps<SVGElement>>;
                    }>,
                );
                return (
                  <li key={key}>
                    {key === "copy" ? (
                      <button
                        className="flex items-center gap-x-sm p-sm"
                        title={config.title}
                        onClick={() => {
                          handleCopyToClipboard(
                            config.getUrl(articleUrl, articleTitle),
                          );
                          sendGaEvent(config.ga);
                        }}
                      >
                        <Suspense fallback={<div className="size-[18px]" />}>
                          <Icon className="size-[18px] fill-none" />
                        </Suspense>
                        <span className="whitespace-nowrap">{config.name}</span>
                      </button>
                    ) : (
                      <a
                        className="flex items-center gap-x-sm p-sm"
                        href={config.getUrl(articleUrl, articleTitle)}
                        title={config.title}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          sendGaEvent(config.ga);
                        }}
                      >
                        <Suspense fallback={<div className="size-[18px]" />}>
                          <Icon className="size-[18px]" />
                        </Suspense>
                        <span className="whitespace-nowrap">{config.name}</span>
                      </a>
                    )}
                  </li>
                );
              })}
          </ul>
        </div>
      )}
    </div>
  );
}
