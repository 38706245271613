import cx from "classnames";
import type { ReactElement, ReactNode } from "react";

export type ButtonProps = {
  children: ReactNode;
  variant: "primary" | "secondary" | "text";
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
  testId?: string;
};

export default function Button({
  children,
  variant,
  onClick,
  testId,
}: ButtonProps): ReactElement {
  const classNames = cx(
    "align-center flex h-fit w-fit justify-center p-sm font-secondary",
    {
      "border-primary-500 bg-white text-primary-500 hover:border-primary-700 hover:bg-primary-500 hover:text-white":
        variant === "primary",
      "border-primary-700 bg-primary-500 text-white hover:border-primary-500 hover:bg-white hover:text-primary-500":
        variant === "secondary",
      "border-r-3 border-b-3 border-l border-t text-16 font-bold leading-1 uppercase":
        variant !== "text",
      "text-13 font-semibold leading-1.2 text-primary-500 hover:underline":
        variant === "text",
    },
  );

  return (
    <button className={classNames} onClick={onClick} data-testid={testId}>
      {children}
    </button>
  );
}
