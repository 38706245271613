import { SOCIAL_LINKS, type SocialPlatform } from "@app/config/social";
import { lazy, type ReactElement, Suspense } from "react";

export type SocialIconLinkProps = {
  variant: SocialPlatform;
  iconClassName?: string;
};

export default function SocialIconLink({
  variant,
  iconClassName,
}: SocialIconLinkProps): ReactElement {
  const config = SOCIAL_LINKS[variant];

  const Icon = lazy(
    () =>
      import(`../../../assets/icons/${config.icon}.svg`) as Promise<{
        default: React.FC<React.SVGProps<SVGElement>>;
      }>,
  );

  return (
    <a href={config.url} title={config.title} target="_blank" rel="noreferrer">
      <Suspense fallback={<div className={iconClassName} />}>
        <Icon className={iconClassName} />
      </Suspense>
    </a>
  );
}
