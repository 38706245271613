import { ADS_DOMAIN } from "@app/config/ads";
import { Helmet } from "react-helmet-async";

import adsOverlayCss from "./adsOverlayCss.head.css?raw";

// a self executing function injected into the head
const overlaySEF = function () {
  const htmlClassList = document.documentElement.classList;
  const isDfpTest = window.location.search.includes("dfp_preview");

  if (
    screen.width > 767 &&
    (!document.cookie.includes("topoverlayDisplayed=yes") || isDfpTest)
  ) {
    htmlClassList.add("hasoverlay");
    setTimeout(function () {
      if (!htmlClassList.contains("hasprestitial")) {
        htmlClassList.remove("hasoverlay");
      }
    }, 4e3);
  }
};

export default function AdSettings() {
  return (
    <Helmet>
      <>
        <script>{`(${overlaySEF.toString()})()`}</script>
        <style type="text/css">{adsOverlayCss}</style>
        <link
          href={`${ADS_DOMAIN}/tag/smx/sites/common/smx_prebid.js`}
          rel="preload"
          as="script"
        />
      </>
    </Helmet>
  );
}
