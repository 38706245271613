import { ARTICLE_COUNT, ARTICLE_GROUP_LAYOUT } from "@app/config/article";
import { useLoadMore } from "@app/hooks/useLoadMore";
import PageHeader from "@components/Elements/PageHeader/PageHeader";
import ArticleList from "@components/Layouts/ArticleList/ArticleList";
import Advertisement from "@elements/Advertisement/Advertisement";
import Button from "@elements/Button/Button";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getWindowHref, replaceSpecialChars } from "@helpers/utils";
import BasePage, { type BasePageProps } from "@pages/BasePage";
import StatusHandler from "@pages/StatusHandler/StatusHandler";
import {
  type CustomContext,
  ResponseType,
  type TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { type ReactElement } from "react";

import type { ResponsePayload } from "./Section.server";
import { SectionMetaData } from "./SectionMetaData";

export default function SectionPage(): ReactElement {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<ResponsePayload, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;

  const section =
    dataLoaderResponse.type === ResponseType.SUCCESS
      ? dataLoaderResponse.payload.section
      : { name: "", uniqueName: "", parent: { name: "", uniqueName: "" } };
  const articles =
    dataLoaderResponse.type === ResponseType.SUCCESS
      ? dataLoaderResponse.payload.articles
      : [];
  const totalArticles =
    dataLoaderResponse.type === ResponseType.SUCCESS
      ? dataLoaderResponse.payload.totalArticles
      : 0;
  const threshold = ARTICLE_GROUP_LAYOUT["category-top"].count;
  const topArticles = articles.slice(0, threshold);
  const bottomArticles = articles.slice(threshold);

  const { moreArticles, fetchMoreArticles, hasMore, isLoading } = useLoadMore({
    endpoint: "more-section-articles",
    pageSize: ARTICLE_COUNT.category.additional,
    initialArticles: articles,
    totalArticles: totalArticles,
  });

  const sectionName = section.uniqueName.toLowerCase();
  const parentSection = section.parent.uniqueName.toLowerCase();

  const { gaData, metaTags } = SectionMetaData({
    sectionName,
    parentSection,
  });
  const pageName = replaceSpecialChars(section.uniqueName);
  const basePageProps: BasePageProps = {
    gaData,
    metaTags: metaTags,
    pageName: pageName,
  };

  const sendGaEvent = useGTMDispatch();
  const handleLoadMore = () => {
    sendGaEvent({
      event: "custom_event",
      clickCategory: "load more",
      clickAction: "click",
      clickLabel: getWindowHref(),
    });
    fetchMoreArticles({
      keyword: section.uniqueName,
      size: ARTICLE_COUNT.category.additional,
    })
      .then(() => {})
      .catch(() => {});
  };

  if (dataLoaderResponse.type !== ResponseType.SUCCESS) {
    return <StatusHandler response={dataLoaderResponse} />;
  }

  return (
    <BasePage {...basePageProps}>
      <section>
        <PageHeader title={section.name} />
        <div className="mb-lg flex flex-col gap-y-lg">
          <ArticleList variant="category-top" articles={topArticles} />
          <div className="flex flex-col-reverse gap-x-sm gap-y-lg lg:flex-row">
            <div className="basis-3/4">
              {bottomArticles.length > 0 && (
                <>
                  <ArticleList
                    variant="category-bottom"
                    articles={bottomArticles}
                  />
                  <Advertisement
                    variant="bn1"
                    pageName={pageName}
                    pageNumber={0}
                    rootClassName="mt-lg"
                  />
                </>
              )}
            </div>
            <aside className="flex min-w-[300px] justify-center overflow-clip bg-grayscale-100 py-md lg:basis-1/4 lg:bg-white lg:py-0">
              <Advertisement variant="imu2" pageName={pageName} isSticky />
            </aside>
          </div>
          {moreArticles.map((articles, index) => (
            <div
              key={`article-group-${index}`}
              className="flex flex-col-reverse gap-x-sm gap-y-lg lg:flex-row"
            >
              <div className="basis-3/4">
                {articles.length > 0 && (
                  <>
                    <ArticleList
                      variant="category-bottom"
                      articles={articles}
                    />
                    <Advertisement
                      variant="bn1"
                      pageName={pageName}
                      pageNumber={index + 1}
                      rootClassName="mt-lg"
                      isCompanionAds
                    />
                  </>
                )}
              </div>

              <aside className="flex min-w-[300px] justify-center overflow-clip bg-grayscale-100 py-md lg:basis-1/4 lg:bg-white lg:py-0">
                <Advertisement
                  variant="imu2"
                  pageName={pageName}
                  pageNumber={index + 1}
                  isSticky
                  isCompanionAds
                />
              </aside>
            </div>
          ))}
          <div className="my-md flex items-center justify-center">
            {hasMore ? (
              <Button variant="primary" onClick={handleLoadMore}>
                {isLoading ? "Loading..." : "Read More Stories"}
              </Button>
            ) : (
              <span className="font-secondary text-24 font-bold leading-1.2">
                You have reached the end.
              </span>
            )}
          </div>
        </div>
      </section>
    </BasePage>
  );
}
