import { NAV_ITEMS } from "@app/config/navbar";
import InternalLink from "@elements/InternalLink/InternalLink";
import cx from "classnames";
import type { ReactElement } from "react";
import { useLocation } from "react-router-dom";

export type NavbarProps = {
  variant: "header" | "footer" | "menu";
};

export default function Navbar({ variant }: NavbarProps): ReactElement {
  const { pathname } = useLocation();
  const paths = pathname.split("/");
  const section = paths[2] ? paths[1] + "/" + paths[2] : paths[1];

  const navClassNames = cx("flex flex-col", {
    "w-fit items-center lg:flex-row": variant !== "menu",
    "gap-y-md": variant !== "footer",
    "gap-y-sm": variant === "footer",
  });
  const navItemClassNames = cx("flex", {
    "hover:bg-primary-500 hover:text-white": variant !== "footer",
    "h-fit text-white hover:bg-primary-600": variant === "footer",
    "items-center p-sm font-tertiery text-16 font-semibold leading-1":
      variant !== "menu",
    "font-secondary text-26 p-sm font-bold leading-1.5 text-primary-500":
      variant === "menu",
  });
  return (
    <nav className="flex">
      <ul className={navClassNames}>
        {NAV_ITEMS.map((item) => {
          const isActive =
            paths.length > 1 &&
            paths.length < 4 &&
            item.url === `/${section || ""}`;

          return (
            <li key={item.label} className="flex w-fit">
              <InternalLink
                to={item.url}
                className={cx(navItemClassNames, {
                  [`bg-primary-500 text-white`]:
                    variant !== "footer" && isActive,
                  [`bg-primary-600 text-white`]:
                    variant === "footer" && isActive,
                })}
              >
                {item.label}
              </InternalLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}
