import TnpLogo from "@app/assets/logo/tnp-logo.png";
import { formatDate } from "@app/helpers/dateFormat";
import { removeLeadingSlash } from "@app/helpers/utils";
import { PRD_BASE_HOST, PRD_BASE_URL } from "@helpers/getEnvVariables";
import type { ProcessedArticle } from "@typings/OpenSearch";

export function getArticleJSONLD(article: ProcessedArticle): object {
  const { url, title, media, standfirst, publishedDate, updatedDate, author } =
    article;

  const jsonLd = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "NewsArticle",
        headline: title,
        name: title,
        description: `${standfirst}. Read more at ${PRD_BASE_HOST}`,
        image: {
          "@type": "ImageObject",
          representativeOfPage: "True",
          url: media[0]?.caasUrl,
        },
        datePublished: formatDate(
          publishedDate,
          "yyyy-MM-dd'T'HH:mm:ss+08:00",
          true,
        ),
        dateModified: formatDate(
          updatedDate,
          "yyyy-MM-dd'T'HH:mm:ss+08:00",
          true,
        ),
        isAccessibleForFree: "True",
        author: {
          "@type": "Person",
          name: author.username,
          url: `${PRD_BASE_URL}${removeLeadingSlash(author.urlPath || "")}`,
        },
        publisher: {
          "@type": "Organization",
          name: "The New Paper",
          url: PRD_BASE_URL,
          logo: {
            "@type": "ImageObject",
            url: `${PRD_BASE_URL}${removeLeadingSlash(TnpLogo || "")}`,
          },
        },
        mainEntityOfPage: `${PRD_BASE_URL}${removeLeadingSlash(url || "")}`,
      },
    ],
  };

  return jsonLd;
}
