import DataWrapperEmbed from "@elements/Embeds/DataWrapperEmbed";
import FacebookEmbedCustom from "@elements/Embeds/FacebookEmbedCustom";
import InstagramEmbedCustom from "@elements/Embeds/InstagramEmbedCustom";
import TiktokEmbedCustom from "@elements/Embeds/TiktokEmbedCustom";
import XEmbedCustom from "@elements/Embeds/XEmbedCustom";
import YouTubeEmbedCustom from "@elements/Embeds/YouTubeEmbedCustom";

export default function SocialEmbedFactory(platform: string) {
  switch (platform) {
    case "instagram":
      return InstagramEmbedCustom;
    case "tiktok":
      return TiktokEmbedCustom;
    case "youtube":
      return YouTubeEmbedCustom;
    case "facebook":
      return FacebookEmbedCustom;
    case "twitter":
    case "x": // Both "twitter" and "x" will return the same value
      return XEmbedCustom;
    case "datawrapper":
      return DataWrapperEmbed;
    default:
      return false;
  }
}
