import { useWindowSize } from "@app/hooks/useWindowSize/useWindowSize";
import { ReactElement } from "react";

const sizeMapping: {
  sm: string;
  lg: string;
} = {
  sm: "(max-width: 640px)",
  lg: "1280px",
};

export enum ImageCrop {
  NORMAL = "normal",
  FITWIDTH = "fitWidth",
  FITHEIGHT = "fitHeight",
  PROPOTIONAL = "proportional",
}

export type ImageProps = {
  className?: string;
  src: string;
  alt: string;
  width: { [key: string]: number };
  height: { [key: string]: number };
  srcWidth?: number;
  srcHeight?: number;
  loading?: "lazy" | "eager";
  imageCrop?: ImageCrop;
};

/**
 * Image
 * This is Image Component
 */
export function Image({
  className = "",
  src,
  alt,
  width,
  height,
  srcWidth,
  srcHeight,
  loading,
  imageCrop,
}: ImageProps): ReactElement {
  const { isScreenSM } = useWindowSize();

  let isVertical = false;

  // Determine if the image is vertical or horizontal
  if (imageCrop === ImageCrop.PROPOTIONAL && srcWidth && srcHeight) {
    isVertical = srcHeight > srcWidth;
  }

  let optimized = `${src}?w=${isScreenSM ? width.sm : width.lg}&h=${isScreenSM ? height.sm : height.lg}`;

  // Generate srcSet
  const srcSet = Object.keys(width)
    .map((key) => {
      if (imageCrop === ImageCrop.PROPOTIONAL) {
        // Include only height for vertical, or width for horizontal
        if (isVertical) {
          return `${src}?h=${height[key]} ${width[key]}w`;
        } else {
          return `${src}?w=${width[key]} ${width[key]}w`;
        }
      } else if (imageCrop === ImageCrop.FITWIDTH) {
        optimized = `${src}?w=${isScreenSM ? width.sm : width.lg}`;
        return `${src}?w=${width[key]} ${width[key]}w`;
      } else if (imageCrop === ImageCrop.FITHEIGHT) {
        optimized = `${src}?h=${isScreenSM ? height.sm : height.lg}`;
        return `${src}?h=${height[key]} ${width[key]}w`;
      } else {
        // Include both height and width when imageCrop is not proportional
        return `${src}?w=${width[key]}&h=${height[key]} ${width[key]}w`;
      }
    })
    .join(", ");

  const sizes = Object.keys(sizeMapping)
    .map(
      (key) =>
        `${sizeMapping[key as keyof typeof sizeMapping]} ${width[key]}px`,
    )
    .join(", ");

  const newClassName = `${className} ${imageCrop === ImageCrop.FITHEIGHT ? "float-right" : ""}`;

  return (
    <img
      className={newClassName}
      src={optimized}
      srcSet={srcSet}
      sizes={sizes}
      alt={alt}
      loading={loading}
    />
  );
}
