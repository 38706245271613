import InternalLink from "@elements/InternalLink/InternalLink";
import { encodeUrlWithPrefix } from "@helpers/utils";
import type { ReactElement } from "react";

export type TagProp = {
  label: string;
  url: string;
};

export default function Tag({ label, url }: TagProp): ReactElement {
  const prefix = "/tag/";
  const encodedUrl = encodeUrlWithPrefix(prefix, url);

  return (
    <InternalLink
      className="block w-fit whitespace-nowrap border-2 border-primary-500 bg-white p-sm text-14 font-semibold uppercase italic leading-1 text-primary-500 transition-colors hover:bg-primary-500 hover:text-white"
      to={`${encodedUrl}?ref=end-of-article`}
    >
      {label}
    </InternalLink>
  );
}
