import type { ArticleBylineBasic } from "@typings/OpenSearch";

export const TEAM_MEMBERS: ArticleBylineBasic[] = [
  {
    id: "shazalina-salim",
    name: "Shazalina Salim",
    designation: "Assistant Digital Editor",
    email: "slina@sph.com.sg",
    photo: "/team/shazalina-salim.jpg",
    urlPath: "/author/shazalina-salim",
  },
  {
    id: "nathaniel-fetalvero",
    name: "Nathaniel Fetalvero",
    designation: "Correspondent",
    email: "njfetalvero@sph.com.sg",
    photo: "/team/nathaniel-fetalvero.jpg",
    urlPath: "/author/nathaniel-fetalvero",
  },
  {
    id: "poong-qi-tao",
    name: "Poong Qi Tao",
    designation: "Journalist",
    email: "qitao@sph.com.sg",
    photo: "/team/poong-qi-tao.jpg",
    urlPath: "/author/poong-qi-tao",
  },
  {
    id: "jaden-darrius-png",
    name: "Jaden Darrius Png",
    designation: "Journalist",
    email: "jadenpng@sph.com.sg",
    photo: "/team/jaden-darrius-png.jpg",
    urlPath: "/author/jaden-darrius-png",
  },
];
