import ArticlePage from "@pages/Article/Article";
import AuthorPage from "@pages/Author/Author";
import HomePage from "@pages/Home/Home";
import RaceCardsPage from "@pages/RaceCards/RaceCards";
import SearchPage from "@pages/Search/Search";
import SectionPage from "@pages/Section/Section";
import ContactPage from "@pages/static/Contact";
import TeamPage from "@pages/static/Team";
import StatusHandler from "@pages/StatusHandler/StatusHandler";
import StoryQueue from "@pages/StoryQueue/StoryQueue";
import TagPage from "@pages/Tag/Tag";
import { AuthCallback } from "@sphtech/web2-core/auth";
import { fetchContext, ResponseType } from "@sphtech/web2-core/ssr";
import { getClientId, getIssuer } from "@web2/web2-helpers/auth/MySphConfig.ts";
import { RouteObject } from "react-router-dom";

import App from "./App";
import { STORY_QUEUE_TOP_STORIES_ID } from "./caas/constants";

const errorElement = import.meta.env.PROD ? (
  <StatusHandler clientSideError />
) : undefined;

export const reactRouterRoutes: RouteObject[] = [
  {
    path: "/",
    element: <App />,
    children: [
      {
        index: true,
        element: <HomePage showTikTok={false} showMostTalkedAbout={false} />,
        errorElement,
        loader: fetchContext,
      },
      /**
       * Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
       */
      {
        path: "/oauth/callback",
        element: <AuthCallback clientId={getClientId()} issuer={getIssuer()} />,
        errorElement,
      },
      {
        path: "/team",
        element: <TeamPage />,
        errorElement,
      },
      {
        path: "/contact-us",
        element: <ContactPage />,
        errorElement,
      },
      {
        path: "/sports/race-cards",
        element: <RaceCardsPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: `/article/${STORY_QUEUE_TOP_STORIES_ID}`,
        element: <StoryQueue />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/sitesearch",
        element: <SearchPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/author/:name",
        element: <AuthorPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/tag/:level",
        element: <TagPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/:level1",
        element: <SectionPage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/:level1/:articleId",
        element: <ArticlePage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "/:level1/:level2/:articleId",
        element: <ArticlePage />,
        errorElement,
        loader: fetchContext,
      },
      {
        path: "*",
        element: (
          <StatusHandler
            response={{
              type: ResponseType.CLIENT_ERROR,
              statusCode: 404,
            }}
          />
        ),
        errorElement,
      },
    ],
  },
];

export const routes = reactRouterRoutes.reduce(
  (_routes: string[], currentRoute: RouteObject) => {
    if (currentRoute.path) {
      _routes = [..._routes, currentRoute.path];
    }

    if (currentRoute.children) {
      for (const child of currentRoute.children) {
        if (child.path) {
          _routes = [..._routes, child.path];
        }
      }
    }

    return _routes;
  },
  [],
);
