import Tag from "@elements/Tag/Tag";
import type { ArticleTag } from "@typings/OpenSearch";
import type { ReactElement } from "react";

export type TagListProp = {
  tags: ArticleTag[];
};

export default function TagList({ tags }: TagListProp): ReactElement | null {
  const filtered = tags.filter((tag) => tag.urlPath);

  return filtered.length > 0 ? (
    <ul className="flex flex-wrap gap-sm">
      {filtered.map(({ id, name, urlPath }) => (
        <li key={`tag-${id}`}>
          <Tag label={name} url={urlPath} />
        </li>
      ))}
    </ul>
  ) : null;
}
