import { PRD_BASE_URL } from "@app/helpers/getEnvVariables";
import { isClientSide, removeTrailingSlash } from "@app/helpers/utils";
import Cookies from "js-cookie";
import { useCallback, useEffect } from "react";

export default function Outbrain({
  pathName,
  version,
}: {
  pathName: string;
  version: "AR_1" | "SB_2";
}) {
  const embedOutbrain = useCallback(() => {
    if (!isClientSide) return;

    /* eslint-disable @typescript-eslint/no-unsafe-member-access */
    /* eslint-disable @typescript-eslint/no-unsafe-call */
    if (window.OBR) {
      window.OBR.extern.researchWidget();
    } else {
      setTimeout(embedOutbrain, 50);
    }
  }, []);

  useEffect(() => {
    embedOutbrain();
  }, [embedOutbrain]);

  useEffect(() => {
    if (isClientSide) {
      /* eslint-disable @typescript-eslint/no-unsafe-member-access */
      if (
        typeof window._data != "undefined" &&
        typeof window._data?.articleid != "undefined" &&
        window._data?.articleid != ""
      ) {
        Cookies.set("outbrain_enable", "1");
      } else {
        Cookies.set("outbrain_enable", "0");
      }
    }
  }, []);
  return (
    <div
      className="OUTBRAIN"
      data-src={`${removeTrailingSlash(PRD_BASE_URL)}${pathName}`}
      data-widget-id={version}
      data-ob-template="TheNewPaper"
    ></div>
  );
}
