import type { ReactElement } from "react";
import { Link, LinkProps } from "react-router-dom";

export interface InternalLinkProps extends LinkProps {}

export default function InternalLink(
  props: React.PropsWithChildren<InternalLinkProps>,
): ReactElement {
  const { children, ...rest } = props;

  return (
    <Link reloadDocument {...rest}>
      {children}
    </Link>
  );
}
