import type { PageMetaDataValues } from "@app/types/PageMetaData";
import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { PRD_BASE_HOST } from "@helpers/getEnvVariables";
import { convertKebabToSentence, replaceSpecialChars } from "@helpers/utils";

export type TagMetaDataProps = {
  tagName: string;
};

export function TagMetaData({ tagName }: TagMetaDataProps): PageMetaDataValues {
  const capitalizeTagName = convertKebabToSentence(tagName.replace("-", " "));
  const metaTitle = `Latest ${capitalizeTagName} News & Headlines, Top Stories Today - The New Paper`;
  const metaDescription: string | undefined =
    `The New Paper ${capitalizeTagName} News - Get latest ${capitalizeTagName} news. Find top stories published today at ${PRD_BASE_HOST}`;

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug: "tag/" + replaceSpecialChars(tagName),
  };

  const level2 = tagName.toLowerCase();

  const gaData: GADataProps = {
    level2: level2,
    title: `${capitalizeTagName}_Index`,
    section: level2,
    chapter1: "",
    contentcat: "1",
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
