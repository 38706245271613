import { AdTargetingType } from "@typings/Ads";
import { useCallback, useEffect } from "react";

import useLoadedTags from "./useLoadedTags";

type GlobalAdSettingProps = {
  targeting?: AdTargetingType[];
};

export default function useGlobalAdSetting({
  targeting,
}: GlobalAdSettingProps = {}) {
  useLoadedTags();

  const handleGlobalAdsetting = useCallback(() => {
    googletag.cmd.push(function () {
      targeting &&
        targeting.forEach((target: AdTargetingType) => {
          googletag.pubads().setTargeting(target.key, target.value);
        });

      googletag.pubads().enableSingleRequest();
      googletag.pubads().collapseEmptyDivs();
    });
  }, [targeting]);

  useEffect(() => {
    if (
      typeof window !== "undefined" &&
      window.googletag &&
      googletag.apiReady
    ) {
      handleGlobalAdsetting();
    }
  }, [handleGlobalAdsetting]);
}
