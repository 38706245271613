export const GTM_ID = "GTM-MV8N3GX";

export const Referers = {
  HOME_TOPREADS: "home-topreads",
  HOME_TOP_MAIN: "home-top-main",
  HOME_TOP_LIST: "home-top-list",
  TOPSTORIES_WEEK: "article-top-stories-this-week",
  TOPSTORIES_INLINE: "article-related-stories-inline",
  TOPSTORIES_BOTTOM: "article-related-stories-bottom",
  CATEGORY_TOP: "section-top",
  CATEGORY_LIST: "section-list",
  SEARCH: "search",
  NONE: "",
} as const;
