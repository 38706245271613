import { SOCIAL_LINKS } from "@app/config/social";
import InternalLink from "@elements/InternalLink/InternalLink";
import SocialIconLink from "@elements/SocialIconLink/SocialIconLink";
import BasePage from "@pages/BasePage";
import { ResponseType, type TRouteWithRedirect } from "@sphtech/web2-core/ssr";
import { type ReactElement, type ReactNode, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

type Response = TRouteWithRedirect<unknown, string>;
type ErrorPageProps = Readonly<{
  response?: Response;
  clientSideError?: true;
}>;

const textForStatusCode: Partial<
  Record<
    Response["statusCode"] | Response["type"] | "clientSideError",
    { title: string; content: ReactNode }
  >
> = {
  [ResponseType.REDIRECT]: {
    title: "Redirecting",
    content: <p>You are being redirected.</p>,
  },
  [ResponseType.CLIENT_ERROR]: {
    title: "Bad Request",
    content: (
      <>
        <p>We are sorry, the page you are looking for cannot be served.</p>
        <p>There is a problem with your request.</p>
      </>
    ),
  },
  [ResponseType.SERVER_ERROR]: {
    title: "Unexpected Error",
    content: (
      <>
        <p>Oops, something went wrong.</p>
        <p>Try refreshing this page or contact us if the problem persists.</p>
      </>
    ),
  },
  404: {
    title: "Page not found",
    content: (
      <>
        <p>The link you followed may be broken or may have been removed.</p>
        <p>
          Try refreshing the page or head back to our{" "}
          <InternalLink className="text-primary-500 hover:underline" to="/">
            homepage
          </InternalLink>
          .
        </p>
      </>
    ),
  },
  503: {
    title: "Site not found",
    content: (
      <>
        <p>We are working on it. Sorry for any inconvenience caused.</p>
        <p className="flex flex-wrap justify-center gap-x-md">
          <span>In the meantime, get the latest news from:</span>
          <ul className="flex justify-center gap-x-md">
            {(Object.keys(SOCIAL_LINKS) as (keyof typeof SOCIAL_LINKS)[]).map(
              (key) => (
                <li key={key}>
                  <SocialIconLink
                    variant={key}
                    iconClassName="size-icon-xlpx] fill-primary-500"
                  />
                </li>
              ),
            )}
          </ul>
        </p>
      </>
    ),
  },
  clientSideError: {
    title: "Unexpected Error",
    content: (
      <>
        <p>Oops, something went wrong.</p>
        <p>
          Try refreshing this page, updating your browser or contact us if the
          problem persists.
        </p>
      </>
    ),
  },
};

export default function ErrorPage({
  response,
  clientSideError,
}: ErrorPageProps): ReactElement {
  const navigate = useNavigate();
  const text =
    (clientSideError
      ? textForStatusCode.clientSideError
      : ((response?.statusCode !== undefined &&
          textForStatusCode[response.statusCode]) ??
        (response?.type !== undefined && textForStatusCode[response.type]))) ||
    textForStatusCode[ResponseType.SERVER_ERROR];

  useEffect(() => {
    if (response?.type === ResponseType.REDIRECT) {
      navigate(response.target);
    }
  }, [response, navigate]);

  return (
    <BasePage isErrorPage>
      <Helmet>
        <title>{`${text?.title} — The New Paper`}</title>
      </Helmet>
      <section className="my-lg flex grow flex-col items-center justify-center gap-y-lg text-center">
        <h1 className="font-secondary text-34 font-extrabold leading-1">
          {text?.title}
        </h1>
        <div className="flex flex-col gap-y-lg text-24 font-semibold leading-1.2 lg:text-26">
          {response?.type === ResponseType.REDIRECT ? (
            <>
              {text?.content}
              <InternalLink to={response.target}>
                <u>Please click here if your browser does not redirect you.</u>
              </InternalLink>
            </>
          ) : (
            text?.content
          )}
          {(response?.statusCode === 404 || response?.statusCode === 503) && (
            <p className="text-18 lg:text-20">
              For urgent assistance, please contact our Customer Service team{" "}
              <br />
              at{" "}
              <Link
                className="text-primary-500 hover:underline"
                to="tel:63883838"
              >
                6388-3838
              </Link>{" "}
              or email us at{" "}
              <Link
                className="text-primary-500 hover:underline"
                to="mailto:tnp@sph.com.sg"
              >
                tnp@sph.com.sg
              </Link>
              .
            </p>
          )}
        </div>
      </section>
    </BasePage>
  );
}
