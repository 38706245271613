import ArticleList from "@components/Layouts/ArticleList/ArticleList";
import SectionHeader from "@elements/SectionHeader/SectionHeader";
import IcArrowDown from "@icons/arrow-down.svg";
import type { ProcessedArticle } from "@typings/OpenSearch";
import type { ReactElement } from "react";
import { useNavigate } from "react-router-dom";

export type RelatedStoryListProps = {
  variant: "single" | "multiple";
  articles: ProcessedArticle[];
  hasMore?: boolean;
};

export default function RelatedStoryList({
  variant,
  articles,
  hasMore = true,
}: RelatedStoryListProps): ReactElement {
  const navigate = useNavigate();

  const handleRelatedStories = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault(); // Prevent full-page reload

    const url = new URL(window.location.href);
    url.searchParams.set("ref", "related-stories");
    navigate(`${url.pathname}${url.search}#related-stories`, { replace: true });

    // Smooth scroll to the target anchor
    document
      .getElementById("related-stories")
      ?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex flex-col">
      <SectionHeader
        page="article"
        title={variant === "single" ? "Related Story" : "Related Stories"}
      />
      {variant === "single" ? (
        <>
          <ArticleList variant="article-related-single" articles={articles} />
          {hasMore && (
            <div className="m-md flex justify-center">
              <a
                className="flex items-center gap-x-sm font-secondary text-16 font-medium text-primary-500 hover:underline"
                href="#related-stories"
                onClick={handleRelatedStories}
              >
                <span>More related stories</span>
                <IcArrowDown className="size-icon-sm" />
              </a>
            </div>
          )}
        </>
      ) : (
        <div className="flex flex-col md:flex-row">
          <div className="basis-2/5">
            <ArticleList
              variant="article-related-multiple-left"
              articles={articles.slice(0, 1)}
            />
          </div>
          <div className="basis-3/5">
            <ArticleList
              variant="article-related-multiple-right"
              articles={articles.slice(1, 4)}
            />
          </div>
        </div>
      )}
    </div>
  );
}
