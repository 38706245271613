import { MAX_DESCRIPTION_LENGTH, MAX_TITLE_LENGTH } from "@app/config/metatag";
import { formatDate } from "@app/helpers/dateFormat";
import { getArticleJSONLD } from "@app/helpers/jsonLDHelper";
import { GADataProps } from "@elements/GAData/GAData";
import {
  getChapter1,
  getLevel2,
  getTagsName,
} from "@elements/GAData/GADataHelper";
import { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { PRD_BASE_HOST } from "@helpers/getEnvVariables";
import {
  convertKebabToSentence,
  replaceSpacesWithUnderscores,
  truncateText,
} from "@helpers/utils";
import { ProcessedArticle } from "@typings/OpenSearch";
import { PageMetaDataValues } from "@typings/PageMetaData";

type ArticleMetaDataProps = {
  article: ProcessedArticle;
};

export default function ArticleMetaData({
  article,
}: ArticleMetaDataProps): PageMetaDataValues {
  const keywords = getTagsName(article.tags);
  const level2 = getLevel2(article);

  // Clearing the "news" value is necessary to prevent duplicate meta titles like "Latest News News".
  const metaLevel2 =
    level2.toLowerCase() !== "news" ? convertKebabToSentence(level2) : "";

  //Meta Title
  const tempTitleLength = `, Latest ${metaLevel2} News - The New Paper`.length;
  const truncatedTitle = truncateText(
    article.title,
    MAX_TITLE_LENGTH - tempTitleLength,
  );
  const metaTitle = `${truncatedTitle}, Latest ${metaLevel2} News - The New Paper`;

  //Meta Description
  const articleDescription = article.standfirst
    ? article.standfirst
    : article.title;
  const staticLength = `${metaLevel2} News -  Read more at ${PRD_BASE_HOST}`
    .length;
  const truncatedDescription = truncateText(
    articleDescription,
    MAX_DESCRIPTION_LENGTH - staticLength,
  );
  const metaDescription = `${metaLevel2} News - ${truncatedDescription} Read more at ${PRD_BASE_HOST}`;

  const gaData: GADataProps = {
    title: replaceSpacesWithUnderscores(article.title),
    level2: level2,
    chapter1: getChapter1(article),
    contentcat: "1", //FREE
    section: level2,
    contenttype: "1",
    pagination: 1,
    cue_articleid: "",
    articleid: article.id,
    keyword: keywords,
    pubdate: formatDate(article.publishedDate, "yyyy-MM-dd HH:mm:ss"),
    content_publication_date_utc: article.createdDate,
    content_update_date_utc: article.updatedDate,
  };

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug: article.url,
    ogType: "Article",
    ogTitle: article.title,
    ogDescription: article.standfirst,
    keywords: keywords.split(","),
    image: article.media[0],
    robotsContent: "index, follow, max-image-preview:large, max-snippet:-1",
    isArticlePage: true,
    publishedTime: article.publishedDate,
    modifiedTime: article.updatedDate,
    twitterCardContent: "summary",
    jsonLd: getArticleJSONLD(article),
  };

  return {
    gaData: gaData,
    metaTags: metaTags,
  };
}
