import { ARTICLE_TYPE_MAP } from "@app/config/article";
import type { ArticleType } from "@typings/OpenSearch";
import cx from "classnames";
import { lazy, type ReactElement, Suspense } from "react";

export type ArticleTypeTagProps = {
  variant: string;
};

export default function ArticleTypeTag({
  variant,
}: ArticleTypeTagProps): ReactElement | null {
  if (!Object.keys(ARTICLE_TYPE_MAP).includes(variant)) return null;

  const config = ARTICLE_TYPE_MAP[variant as ArticleType];

  const Icon = lazy(
    () =>
      import(`../../../assets/icons/${config.icon}.svg`) as Promise<{
        default: React.FC<React.SVGProps<SVGElement>>;
      }>,
  );
  const classNames = cx(
    "flex w-fit items-center gap-x-xs rounded-[4px] px-sm py-xs font-secondary text-14 font-bold leading-1",
    config.color,
    config.bgColor,
    {
      uppercase: variant !== "old",
    },
  );

  return (
    <div className={classNames}>
      <Suspense fallback={<div className="size-icon-md" />}>
        <Icon className="size-icon-md" />
      </Suspense>
      <span>{config.label}</span>
    </div>
  );
}
