interface FooterLink {
  label: string;
  url: string;
  isExternal: boolean;
}

export const FOOTER_LINKS: FooterLink[] = [
  {
    label: "Our Team",
    url: "/team",
    isExternal: false,
  },
  {
    label: "Contact TNP",
    url: "/contact-us",
    isExternal: false,
  },
  {
    label: "About SPH",
    url: "https://www.sph.com.sg",
    isExternal: true,
  },
  {
    label: "Privacy Statement",
    url: "https://www.sph.com.sg/legal/sph_privacy/",
    isExternal: true,
  },
  {
    label: "Data Protection Policy",
    url: "https://www.sph.com.sg/legal/pdpa/",
    isExternal: true,
  },
  {
    label: "Member Terms and Conditions",
    url: "https://www.sph.com.sg/legal/member_conditions/",
    isExternal: true,
  },
  {
    label: "Website Terms and Conditions",
    url: "https://www.sph.com.sg/legal/website_tnc/",
    isExternal: true,
  },
];
