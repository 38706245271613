import Logo from "@app/assets/logo/logo.svg";
import useGlobalAdSetting from "@app/hooks/useGlobalAdSetting";
import useAdVarStore from "@app/store/useAdVarStore";
import Footer from "@components/Modules/Footer/Footer";
import Header from "@components/Modules/Header/Header";
import AdSettings from "@elements/Advertisement/AdSettings";
import Advertisement from "@elements/Advertisement/Advertisement";
import CatfishAd from "@elements/Advertisement/CatfishAd";
import { adslotName, adTarget } from "@elements/Advertisement/helpers";
import PrestitialAd from "@elements/Advertisement/PrestitialAd";
import type { GADataProps } from "@elements/GAData/GAData";
import GAData from "@elements/GAData/GAData";
import InternalLink from "@elements/InternalLink/InternalLink";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import MetaTags from "@elements/MetaTags/MetaTags";
import { AdTargetingType } from "@typings/Ads";
import cx from "classnames";
import type { ReactElement } from "react";

export type BasePageProps = {
  children?: React.ReactNode;
  responsive?: boolean;
  isErrorPage?: boolean;
  metaTags?: MetaTagsProps;
  gaData?: GADataProps;
  pageName?: string;
  targeting?: AdTargetingType[];
};

export default function BasePage({
  children,
  responsive = true,
  isErrorPage = false,
  metaTags,
  gaData,
  pageName = "homepage",
  targeting = adTarget(pageName),
}: BasePageProps): ReactElement {
  const { isPrestitialEnabled, topOverlayImpressions, isCatfishEnabled } =
    useAdVarStore();
  useGlobalAdSetting({
    targeting: targeting,
  });

  const wrapperClassNames = cx(
    "relative flex min-h-dvh w-full flex-col bg-white",
  );
  const mainClassNames = cx("flex grow flex-col py-lg", { responsive });

  return (
    <>
      <AdSettings />
      <PrestitialAd
        slot={adslotName("prestitial", pageName)}
        isPrestitialEnabled={isPrestitialEnabled}
        topOverlayImpressions={topOverlayImpressions}
      />
      <Advertisement variant="abm" pageName={pageName} />

      <div className={wrapperClassNames}>
        {isErrorPage ? (
          <header className="flex justify-center">
            <InternalLink to="/">
              <Logo className="h-[68px] w-[198px] lg:h-full lg:w-auto" />
            </InternalLink>
          </header>
        ) : (
          <>
            {gaData && <GAData {...gaData} />}
            {metaTags && <MetaTags {...metaTags} />}
            <div className="flex min-h-[50px] w-full items-center justify-center bg-grayscale-100 py-md lg:min-h-[120px]">
              <Advertisement variant="lb1" pageName={pageName} />
            </div>
            <Header />
          </>
        )}
        <main className={mainClassNames}>{children}</main>
        {!isErrorPage && <Footer />}
      </div>

      <CatfishAd
        slot={adslotName("catfish", pageName)}
        isCatfishEnabled={isCatfishEnabled}
      />
    </>
  );
}
