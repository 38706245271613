import type { UrlMatch } from "@app/data";

import { ENVIRONMENT, TNP_BASE_URL } from "./getEnvVariables";

export function isArrayWithElements(payload: unknown): payload is unknown[] {
  return Array.isArray(payload) && payload.length >= 1;
}

export function isUrlComponentValid(
  urlComponent: UrlMatch["urlComponent"],
  requiredParams: string[],
): boolean {
  return (
    typeof urlComponent === "object" &&
    "params" in urlComponent &&
    requiredParams.every((param) => param in urlComponent.params)
  );
}

export function removeLeadingSlash(s: string): string {
  return s && (typeof s === "string" && s.startsWith("/") ? s.slice(1) : s);
}

export function removeTrailingSlash(s: string): string {
  return s.endsWith("/") ? s.substring(0, s.length - 1) : s;
}

export function convertKebabToSentence(kebabString: string) {
  return kebabString
    .toLowerCase()
    .replace(/-/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
}

export function convertTimeToLowercase(time: string) {
  return time.replace(" AM", "am").replace(" PM", "pm");
}

export const sanitizeSearchKeyword = (searchValue: string) => {
  const sanitizedInput = searchValue.replace(/[^\w\s]/gi, "").substring(0, 255);
  return sanitizedInput;
};

/**
 * Description of the function.
 *
 * @param {Type} callback - Callback for this function.
 * @param {Type} maxRetries - No. of tries wanted to do.
 * @param {Type} initialDelayMs - Initial delay for each retry.
 * @param {Type} maxDelayMs - Maximum allowed delay.
 */
export async function fetchWithRetry<T>(
  callback: () => Promise<T>,
  maxRetries: number,
  initialDelayMs: number,
  maxDelayMs: number,
): Promise<T> {
  let retries = 0;
  let delay = initialDelayMs;

  while (retries < maxRetries) {
    try {
      return await callback();
    } catch (error) {
      retries++;
      if (retries >= maxRetries) {
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
      delay = Math.min(delay * 2, maxDelayMs);
    }
  }

  throw new Error("Max retries exceeded");
}

export const getWindowOrigin = (): string => {
  if (typeof window !== "undefined") {
    return removeTrailingSlash(window.location.origin);
  } else if (ENVIRONMENT === "local") {
    return "http://localhost:3000";
  }
  return removeTrailingSlash(TNP_BASE_URL);
};

export default async function fetcher(url: string) {
  const r = await fetch(url);
  return r.json();
}

export const getCookieByName = (name: string): string | undefined => {
  if (typeof window === "undefined") {
    return;
  }
  const match = document.cookie.match(new RegExp(name + "=([^;]+)"));
  return match ? match[1] : undefined;
};

export function replaceSpecialChars(text: string) {
  // regex: remove non-alphanumeric chars except dash and replace with dash
  return text.toLowerCase().replace(/[^\w-]+|-+/g, "-");
}

export const truncateText = (text: string, charCount: number) => {
  if (text.length <= charCount) {
    return text;
  }

  const truncatedText = text.substring(0, charCount - 3);
  const lastSpaceIndex = truncatedText.lastIndexOf(" ");

  return lastSpaceIndex > 0
    ? truncatedText.substring(0, lastSpaceIndex) + "..."
    : truncatedText + "...";
};

export function replaceSpacesWithUnderscores(str: string): string {
  // Replace all spaces with underscores
  return str.replace(/\s/g, "_");
}

export const isThisUrlFrom = (url: string, from: string): boolean => {
  try {
    const fixedUrl = url.startsWith("//") ? `https:${url}` : url;

    const domain = new URL(fixedUrl).hostname;
    return domain.includes(from);
  } catch (error) {
    console.error(`Invalid URL: ${url}`, error);
    return false; // Return false if the URL is invalid
  }
};

export const isClientSide: boolean = typeof window !== "undefined";

export const getWindowHref = (): string => {
  if (typeof window !== "undefined") {
    return window.location.href;
  } else if (ENVIRONMENT === "local") {
    return "http://localhost:3000";
  }
  return removeTrailingSlash(TNP_BASE_URL);
};

/**
 * Preserves the order of items in a modified source array based on the original source array.
 *
 * @param {Object} options - The options for preserving the order.
 * @param {string[]} options.originalSource - The original source array.
 * @param {T[]} options.modifiedSource - The modified source array.
 * @template T - It expects a type with an `id` property such article ID.
 */
export const arrayFormatPreserver = <T extends { id: string }[]>(
  originalSource: string[],
  modifiedSource: T,
) => {
  const originalOrder = new Map(
    originalSource.map((item, index) => [item, index]),
  );

  return modifiedSource.sort((a, b) => {
    const aIndex = originalOrder.get(a.id);
    const bIndex = originalOrder.get(b.id);
    if (aIndex !== undefined && bIndex !== undefined) {
      return aIndex - bIndex;
    }
    return 0;
  });
};

export const getUrlHost = (urlInput: string): string => {
  try {
    const url = new URL(urlInput);
    return url.host;
  } catch (error) {
    console.error(`Invalid URL: ${urlInput}`, error);
  }
  return urlInput;
};

export const encodeUrlWithPrefix = (prefix: string, url: string): string => {
  if (url.startsWith(prefix)) {
    const suffix = url.slice(prefix.length);
    return prefix + encodeURIComponent(suffix);
  }

  return encodeURIComponent(url);
};
