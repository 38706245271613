import PageHeader from "@components/Elements/PageHeader/PageHeader";
import { PRD_BASE_HOST } from "@helpers/getEnvVariables";
import BasePage, { type BasePageProps } from "@pages/BasePage";
import type { ReactElement } from "react";

export default function ContactPage(): ReactElement {
  const basePageProps: BasePageProps = {
    gaData: {
      level2: "miscellaneous",
      title: "Contact Us",
      section: "miscellaneous",
      contentcat: "1",
      contenttype: "listing",
    },
    metaTags: {
      title: "Contact Us, Latest News - The New Paper",
      slug: "contact-us",
      description: `News - Read more at ${PRD_BASE_HOST}`,
    },
  };

  return (
    <BasePage {...basePageProps}>
      <section className="my-lg flex flex-col items-center gap-y-lg p-md">
        <PageHeader title="Contact Us" />
        <div className="flex max-w-[718px] flex-col gap-y-md border-b-4 border-l border-r-4 border-t border-primary-500 p-lg text-24 font-semibold leading-1.2 lg:text-26">
          <p className="p-md">
            If you have a story to share, e-mail us at:{" "}
            <a
              className="text-primary-500 underline"
              href="mailto:tnp@sph.com.sg"
            >
              tnp@sph.com.sg
            </a>
          </p>
          <p className="p-md">
            To advertise on TNP,{" "}
            <a
              className="text-primary-500 underline"
              target="_blank"
              href="https://www.sph.com.sg/contact-us/?ctopic=advertising"
              rel="noreferrer"
            >
              click here.
            </a>
          </p>
          <p className="p-md">
            For more information on advertising,{" "}
            <a
              className="text-primary-500 underline"
              target="_blank"
              href="https://www.sph.com.sg/advertising-solutions/"
              rel="noreferrer"
            >
              click here.
            </a>
          </p>
        </div>
      </section>
    </BasePage>
  );
}
