export const preconnect = "https://tags.crwdcntrl.net";
export const dnsprefetch = "https://bcp.crwdcntrl.net";
export const crwdcntrlScript = "https://tags.crwdcntrl.net/lt/c/4343/lt.min.js";
export const crwdcntrlScriptPath =
  "https://ad.crwdcntrl.net/5/c=4343/pe=y/var=ccauds";

export const firstLoad = `
        !function() {
          // Immediately load audiences and id from local storage
          try {
              var storedAuds = window.localStorage.getItem('lotame_4335_auds') || '';
              if (storedAuds) {
                  googletag.cmd.push(function() {
                      window.googletag.pubads().setTargeting('audid', storedAuds.split(','));
                  });
              }

              var localStoragePid = window.localStorage.getItem('_cc_id') || '';
              if (typeof localStoragePid !== undefined || localStoragePid !== null) {
                  googletag.cmd.push(function() {
                      window.googletag.pubads().setTargeting('dfp_cc_id', localStoragePid);
                  });
              }
          } catch(e) {
          }
        } ();
        `;

export const lotameScript = `
        !function() {
          // Callback when targeting audience is ready to push latest audience data
          var audienceReadyCallback = function (profile) {
              // Get audiences as an array
              var lotameAudiences = profile.getAudiences() || [];

              // Set the new target audiences for call to Google
              googletag.cmd.push(function() {
                  window.googletag.pubads().setTargeting('audid', lotameAudiences);
              });

              var lotamePid = profile.getProfileId() || '';
              if (typeof lotamePid !== undefined || lotamePid !== null) {
                  googletag.cmd.push(function() {
                      window.googletag.pubads().setTargeting('dfp_cc_id', lotamePid);
                  });
              }
          };

          // Lotame config
          console.log("load lotame");
          var lotameClientId = '4343';
          var lotameTagInput = {
            data: {},
            config: {
              clientId: Number(lotameClientId),
              audienceLocalStorage: true,
              onProfileReady: audienceReadyCallback(),
            }
          };

          // Lotame initialization
          var lotameConfig = lotameTagInput.config || {};
          var namespace = window['lotame_' + lotameConfig.clientId] = {};
          namespace.config = lotameConfig;
          namespace.data = lotameTagInput.data || {};
          namespace.cmd = namespace.cmd || [];
        } ();
      `;
