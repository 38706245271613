export const ARTICLE_IMAGE = {
  width: {
    sm: 390,
    lg: 965,
  },
  height: {
    sm: 293,
    lg: 543,
  },
} as const;

export const ARTICLE_BODY_IMAGE = {
  width: {
    sm: 390,
    lg: 784,
  },
  height: {
    sm: 293,
    lg: 524,
  },
} as const;

export const GALLERY_IMAGE = {
  width: {
    sm: 390,
    lg: 760,
  },
  height: {
    sm: 293,
    lg: 570,
  },
} as const;

export const AUTHOR_IMAGE = {
  width: {
    sm: 112,
    lg: 112,
  },
  height: {
    sm: 112,
    lg: 112,
  },
} as const;

export const AUTHOR_BYLINE_IMAGE = {
  width: {
    sm: 52,
    lg: 52,
  },
  height: {
    sm: 52,
    lg: 52,
  },
} as const;
