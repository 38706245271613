import IcDownload from "@icons/download.svg";
import { ArticleMedia } from "@typings/OpenSearch";
import cx from "classnames";
import { Link } from "react-router-dom";

export type AttachmentProps = {
  attachment: ArticleMedia;
  className?: string;
};

export default function Attachment({ attachment, className }: AttachmentProps) {
  const titleText = attachment.titleText || "Click here to download";
  const fileName = attachment.caasUrl.split("/").pop();
  const title = attachment.titleText || fileName; //34 max

  return (
    <div data-testid="attachment-pdf" className="my-sm flex">
      <Link
        to={attachment.caasUrl}
        type={attachment.mimeType}
        target="_blank"
        title={titleText}
        rel="noreferrer"
        className={cx(
          className,
          "group/article block w-full border-b-2 border-l-0.5 border-r-2 border-t-0.5 border-transparent p-sm shadow-inactive transition-colors hover:border-primary-500",
        )}
      >
        <div className="flex h-full items-stretch gap-y-sm">
          <div className="flex w-full flex-col gap-y-sm px-xs">
            <div className="flex items-center justify-between">
              <div className="flex-[1_0_0] text-18 font-semibold leading-1.2 lg:text-20">
                {title}
              </div>
              <IcDownload
                data-testid="download-icon"
                className="ml-sm size-icon-lg fill-primary-500"
              />
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
