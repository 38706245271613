import ImagePlaceholder from "@app/assets/image-placeholder.svg";
import { ProcessedTopArticleResponse } from "@app/caas/transformer/getProcessedTopArticles";
import { ARTICLE_COUNT } from "@app/config/article";
import { BUTTON_TITLES, HEADER_HEIGHT_LG } from "@app/config/constants";
import { ARTICLE_IMAGE } from "@app/config/imageresponsive";
import { replaceSpecialChars } from "@app/helpers/utils";
import useSwrApi from "@app/hooks/useSwrApi/useSwrApi";
import useAdVarStore from "@app/store/useAdVarStore";
import Byline from "@components/Blocks/Byline/Byline";
import Gallery from "@components/Blocks/Gallery/Gallery";
import InteractionToolbar from "@components/Blocks/InteractionToolbar/InteractionToolbar";
import ArticleList from "@components/Layouts/ArticleList/ArticleList";
import RelatedStoryList from "@components/Layouts/RelatedStoryList/RelatedStoryList";
import TagList from "@components/Layouts/TagList/TagList";
import Advertisement from "@elements/Advertisement/Advertisement";
import ArticleTypeTag from "@elements/ArticleTypeTag/ArticleTypeTag";
import Attachment from "@elements/Attachment/Attachment";
import DateTime from "@elements/DateTime/DateTime";
import Outbrain from "@elements/Outbrain/Outbrain";
import { Image, ImageCrop } from "@elements/ResponsiveImage/Image";
import Typography from "@elements/Typography/Typography";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getWindowHref, isArrayWithElements } from "@helpers/utils";
import IcTopStories from "@icons/top-stories.svg";
import BasePage, { type BasePageProps } from "@pages/BasePage";
import StatusHandler from "@pages/StatusHandler/StatusHandler";
import { useScript } from "@sphtech/web2-core/hooks";
import {
  type CustomContext,
  ResponseType,
  type TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { type ReactElement, useEffect, useRef, useState } from "react";

import type { ResponsePayload } from "./Article.server";
import ArticleBody from "./ArticleBody";
import ArticleMetaData from "./ArticleMetaData";

export default function ArticlePage(): ReactElement {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<ResponsePayload, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;
  const sendDataToGTM = useGTMDispatch();

  const asideRef = useRef<HTMLDivElement>(null);
  const asideInnerRef = useRef<HTMLDivElement>(null);
  const isAsideFixed = useRef(false);
  const topInteractionToolbarRef = useRef<HTMLDivElement>(null);
  const bottomInteractionToolbarRef = useRef<HTMLDivElement>(null);

  const [showFloating, setShowFloating] = useState(false);
  const [showGallery, setShowGallery] = useState(false);

  const { data: topArticles } = useSwrApi<ProcessedTopArticleResponse[]>({
    endpoint: `top-articles`,
  });

  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    if (showGallery) {
      body.classList.add("overflow-y-hidden");
    } else {
      body.classList.remove("overflow-y-hidden");
    }
  }, [showGallery]);

  useEffect(() => {
    const handleScroll = () => {
      const aside = asideRef.current;
      const asideInner = asideInnerRef.current;

      if (aside && asideInner) {
        const { height: asideHeight, top: asideTop } =
          aside.getBoundingClientRect();
        const { height: asideInnerHeight } = asideInner.getBoundingClientRect();

        isAsideFixed.current = asideTop < HEADER_HEIGHT_LG;
        if (
          isAsideFixed.current &&
          asideHeight + asideTop - HEADER_HEIGHT_LG < asideInnerHeight
        ) {
          asideInner.style.top = "unset";
          asideInner.style.bottom = "0px";
        } else {
          const top = Math.max(0, (asideTop - HEADER_HEIGHT_LG) * -1);
          asideInner.style.top = `${top}px`;
          asideInner.style.bottom = "unset";
        }
      }
    };

    handleScroll();
    globalThis.addEventListener("scroll", handleScroll);

    const observer = new IntersectionObserver((entries) => {
      setShowFloating(!entries[0].isIntersecting);
    });
    if (topInteractionToolbarRef.current) {
      observer.observe(topInteractionToolbarRef.current);
    }
    if (bottomInteractionToolbarRef.current) {
      observer.observe(bottomInteractionToolbarRef.current);
    }

    return () => {
      globalThis.removeEventListener("scroll", handleScroll);
      observer.disconnect();
    };
  }, []);

  // Outbrain script
  useScript({
    id: "article-outbrain",
    src: "https://widgets.outbrain.com/outbrain.js",
    async: true,
  });

  const { isOutbrainEnabled } = useAdVarStore();

  if (dataLoaderResponse.type !== ResponseType.SUCCESS) {
    return <StatusHandler response={dataLoaderResponse} />;
  }

  const {
    article,
    ias = "",
    relatedStoriesArticles = [],
  } = dataLoaderResponse.payload;

  const { gaData, metaTags } = ArticleMetaData({
    article: article,
  });

  const pageName = `${replaceSpecialChars(article.section.parent.uniqueName)}/${replaceSpecialChars(article.section.uniqueName)}`;
  const basePageProps: BasePageProps = {
    gaData,
    metaTags: metaTags,
    pageName: pageName,
    targeting: [
      { key: "page", value: "article" },
      {
        key: "tnptags",
        value: article.tags
          .map((tag) => replaceSpecialChars(tag.name))
          .join(","),
      },
      { key: "tnparticleid", value: article.id },
      { key: "gs_channels", value: ias },
    ],
  };

  /**
   * TODO
   * Check the data structure before trusting this as different
   * publications may have different structures.
   */
  const now = new Date();
  const aYearAgo = new Date();
  aYearAgo.setMonth(now.getMonth() - 12);
  const isOld = new Date(article.publishedDate) < aYearAgo;

  const handleImageClick = () => {
    if (article.media.length > 0) {
      setShowGallery(true);

      //sendGaEvent
      sendDataToGTM({
        event: "custom_event",
        clickCategory: "article photo carousel",
        clickAction: "expand",
        clickLabel: getWindowHref(),
      });
    }
  };

  const handleGalleryClose = () => {
    setShowGallery(false);
  };

  return (
    <BasePage {...basePageProps}>
      <article className="relative flex flex-col gap-y-md">
        <div className="flex flex-col gap-y-md lg:flex-row lg:items-center">
          <button
            className="article-image max-w-viewport lg:basis-1/2"
            title={BUTTON_TITLES.galleryOpen}
            onClick={handleImageClick}
          >
            <figure>
              {article.media[0]?.caasUrl ? (
                <Image
                  src={article.media[0]?.caasUrl}
                  alt={article.media[0]?.caption}
                  width={ARTICLE_IMAGE.width}
                  height={ARTICLE_IMAGE.height}
                  imageCrop={ImageCrop.FITWIDTH}
                  loading="eager"
                />
              ) : (
                <div className="flex aspect-image h-full max-h-full items-center justify-center bg-primary-50 p-md">
                  <ImagePlaceholder className="fill-transparent" />
                </div>
              )}
              {(article.media[0]?.caption || article.media[0]?.credit) && (
                <figcaption className="text-left">
                  {article.media[0]?.caption && (
                    <span>{article.media[0]?.caption}&nbsp;</span>
                  )}
                  {article.media[0]?.credit && (
                    <span>{article.media[0]?.credit}</span>
                  )}
                </figcaption>
              )}
            </figure>
          </button>
          <div className="mx-md flex flex-col gap-y-md lg:basis-1/2">
            {isOld && <ArticleTypeTag variant="old" />}
            {article.articleType && (
              <ArticleTypeTag variant={article.articleType} />
            )}
            <div className="flex flex-row items-center justify-between">
              <DateTime timestamp={article.publishedDate} isRelative />
              {article.kicker && (
                <Typography variant="kicker" text={article.kicker} />
              )}
            </div>
            <Typography variant="headline" text={article.title} />
            {article.subheadline && (
              <Typography variant="subheadline" text={article.subheadline} />
            )}
            <InteractionToolbar
              ref={topInteractionToolbarRef}
              variant="top"
              reactionCounts={article.reactionCounts}
              articleTitle={article.title}
              articleUrl={article.url}
              show={!showFloating}
              showReactions={false}
            />
          </div>
        </div>

        <div className="flex flex-col gap-y-lg lg:flex-row">
          <div className="flex flex-col gap-y-md px-lg lg:w-2/3">
            <div className="flex flex-col gap-y-md py-md">
              <ArticleBody
                elements={article.elements}
                relatedStoriesArticles={relatedStoriesArticles}
                pageName={pageName}
              />
              {article.substories.map((substory, index) => (
                <article
                  key={`article-substory-${index}`}
                  className="mt-md flex flex-col gap-y-md"
                >
                  {substory.title && (
                    <Typography
                      variant="substory-headline"
                      text={substory.title}
                    />
                  )}
                  {substory.byline && <Byline byline={substory.byline} />}
                  {Array.isArray(substory.elements) && (
                    <ArticleBody
                      elements={substory.elements}
                      relatedStoriesArticles={[]}
                      pageName={pageName}
                    />
                  )}
                </article>
              ))}
            </div>
            {isArrayWithElements(article.attachments) && (
              <div className="my-md">
                {article.attachments.map((attachment, index) => (
                  <Attachment
                    attachment={attachment}
                    key={`article-attachment-${index}`}
                    className="lg:w-1/2"
                  />
                ))}
              </div>
            )}
            <Byline byline={article.byline} />
            <TagList tags={article.tags} />
            <InteractionToolbar
              ref={bottomInteractionToolbarRef}
              variant="bottom"
              reactionCounts={article.reactionCounts}
              articleTitle={article.title}
              articleUrl={article.url}
              show={!showFloating}
              showReactions={false}
            />
            {relatedStoriesArticles.length > 1 && (
              <div className="-mx-md" id="related-stories">
                <RelatedStoryList
                  variant="multiple"
                  articles={relatedStoriesArticles.slice(1)}
                />
              </div>
            )}
          </div>

          <aside className="relative flex lg:ml-md lg:w-1/3" ref={asideRef}>
            <div
              className="flex flex-col gap-y-md lg:absolute lg:left-0"
              ref={asideInnerRef}
            >
              <div className="flex justify-center bg-grayscale-100 px-sm py-md lg:mb-3xl lg:bg-white lg:py-0">
                <Advertisement variant="imu2" pageName={pageName} />
              </div>
              {topArticles && (
                <div className="lg:mx-sm">
                  <h2 className="mx-md my-sm flex items-center justify-between">
                    <IcTopStories className="size-icon-lg fill-primary-500" />
                    <span className="m-sm whitespace-nowrap text-center font-secondary text-24 font-semibold leading-1.2">
                      Top Stories This Week
                    </span>
                    <IcTopStories className="size-icon-lg fill-primary-500" />
                  </h2>
                  <ArticleList
                    variant="article-top-stories"
                    articles={topArticles.slice(
                      0,
                      ARTICLE_COUNT.article.topReads,
                    )}
                  />
                </div>
              )}
              {article.url && isOutbrainEnabled && (
                <Outbrain pathName={article.url} version="SB_2" />
              )}
            </div>
          </aside>
        </div>

        <div className="flex lg:mx-md">
          <div className="w-full basis-auto lg:basis-2/3">
            {article.url && isOutbrainEnabled && (
              <Outbrain pathName={article.url} version="AR_1" />
            )}
          </div>
        </div>

        <InteractionToolbar
          variant="floating"
          reactionCounts={article.reactionCounts}
          articleTitle={article.title}
          articleUrl={article.url}
          show={showFloating}
          showReactions={false}
        />

        {showGallery && (
          <Gallery
            media={article.media.filter(
              ({ mimeType }) =>
                mimeType.includes("image") || mimeType.includes("video"),
            )}
            onClose={handleGalleryClose}
          />
        )}
      </article>
    </BasePage>
  );
}
