import { convertTimeToLowercase } from "@app/helpers/utils";
import { NoSSR } from "@sphtech/web2-core/components";
import { type ReactElement } from "react";

export type DateTimeProps = {
  timestamp: string;
  isRelative?: boolean;
};

export const DATETIME_OPTIONS: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "2-digit",
  minute: "numeric",
  hour12: true,
};

export const TIME_OPTIONS: Intl.DateTimeFormatOptions = {
  hour: "2-digit",
  minute: "numeric",
  hour12: true,
};

export default function DateTime({
  timestamp,
  isRelative = false,
}: DateTimeProps): ReactElement {
  const articleDate = new Date(timestamp);
  const todayDate = new Date();
  const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
  let text = new Date(timestamp).toLocaleString("en-US", DATETIME_OPTIONS);

  if (isRelative) {
    if (
      articleDate.toLocaleDateString("en-US") ===
      todayDate.toLocaleDateString("en-US")
    ) {
      text = `Today ${articleDate.toLocaleTimeString("en-US", TIME_OPTIONS)}`;
    } else if (
      articleDate.toLocaleDateString("en-US") ===
      yesterdayDate.toLocaleDateString("en-US")
    ) {
      text = `Yesterday ${articleDate.toLocaleTimeString("en-US", TIME_OPTIONS)}`;
    }
  }

  return (
    <NoSSR>
      <time className="font-secondary text-14 font-medium leading-1.2 text-grayscale-700">
        {convertTimeToLowercase(text)}
      </time>
    </NoSSR>
  );
}
