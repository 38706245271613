import ImagePlaceholder from "@app/assets/image-placeholder.svg";
import type { ArticleGroupLayout } from "@app/config/article";
import { Referers } from "@app/config/gadata";
import DateTime from "@elements/DateTime/DateTime";
import ReactionIcon from "@elements/ReactionIcon/ReactionIcon";
import { Image, ImageCrop } from "@elements/ResponsiveImage/Image";
import IcArrowRight from "@icons/arrow-right.svg";
import IcComment from "@icons/comment.svg";
import type { ProcessedArticle } from "@typings/OpenSearch";
import cx from "classnames";
import type { ReactElement } from "react";

export type ArticleCardProps = {
  layout: ArticleGroupLayout;
  title: string;
  date: string;
  imageUrl: string;
  url: string;
  referer: string;
  reactionCounts?: ProcessedArticle["reactionCounts"];
  isSkyBox: boolean;
  showReactions?: boolean;
};

export default function ArticleCard({
  layout,
  title,
  date,
  imageUrl,
  url,
  referer,
  reactionCounts,
  isSkyBox,
  // TODO: to be removed
  showReactions,
}: ArticleCardProps): ReactElement {
  const { orientation, fontSize, showImage, imageWidth, imageHeight } = layout;

  /**
   * TODO
   * Check the data structure before trusting this as different
   * publications may have different structures.
   */
  const HeadingTag = layout.headingLevel || "h2";

  const emojiCount =
    reactionCounts &&
    (Object.keys(reactionCounts) as (keyof typeof reactionCounts)[]).reduce(
      (prev, curr) => (curr === "comment" ? prev : prev + reactionCounts[curr]),
      0,
    );

  const outerClassNames = cx(
    "group/article block w-full border-b-2 border-l-0.5 border-r-2 border-t-0.5 border-transparent shadow-inactive transition-colors hover:border-primary-500",
    {
      "p-sm": !isSkyBox,
      "px-md py-sm hover:bg-white": isSkyBox,
    },
  );
  const innerClassNames = cx("flex h-full items-stretch gap-y-sm", {
    "flex-col": orientation.item.sm === "vertical",
    "flex-col-reverse justify-end": orientation.item.sm === "vertical-reverse",
    "flex-row gap-x-sm": orientation.item.sm === "horizontal",
    "flex-row-reverse gap-x-sm": orientation.item.sm === "horizontal-reverse",
    "lg:flex-col": orientation.item.lg === "vertical",
    "lg:flex-col-reverse lg:justify-end":
      orientation.item.lg === "vertical-reverse",
    "lg:flex-row lg:gap-x-sm": orientation.item.lg === "horizontal",
    "lg:flex-row-reverse lg:gap-x-sm":
      orientation.item.lg === "horizontal-reverse",
  });
  const imgClassNames = cx("max-h-full aspect-image", {
    "w-2/5": orientation.item.sm.includes("horizontal"),
    "lg:w-1/3": orientation.item.lg.includes("horizontal"),
    "flex items-center justify-center bg-primary-50 p-md": !imageUrl,
  });
  const contentClassNames = cx("flex flex-col gap-y-sm", {
    "w-full px-xs": orientation.item.sm.includes("vertical") || !showImage,
    "w-3/5 px-sm": orientation.item.sm.includes("horizontal"),
    "lg:w-2/3": orientation.item.lg.includes("horizontal"),
  });
  const textClassNames = cx(
    "font-semibold leading-1.2",
    `${fontSize.sm} lg:${fontSize.lg}`,
  );

  /*
   * Handles vertical images
   * Excludes homepage top headline main images and section top headlines
   * to preserve the design layout, responsiveness and visual consistency.
   */
  const imageCrop =
    layout.referer !== Referers.HOME_TOP_MAIN &&
    layout.referer !== Referers.CATEGORY_TOP
      ? ImageCrop.FITHEIGHT
      : ImageCrop.NORMAL;

  return (
    <a className={outerClassNames} href={`${url}?ref=${referer}`}>
      <div className={innerClassNames}>
        {showImage && (
          <div className={imgClassNames}>
            {imageUrl ? (
              <Image
                className="h-full object-cover"
                src={imageUrl}
                alt={title}
                width={imageWidth}
                height={imageHeight}
                imageCrop={imageCrop}
                loading="eager"
              />
            ) : (
              <ImagePlaceholder className="fill-transparent" />
            )}
          </div>
        )}
        <div className={contentClassNames}>
          <div className="flex justify-between">
            <DateTime timestamp={date} />
            <IcArrowRight className="size-icon-sm fill-primary-500 opacity-0 transition-[opacity] group-hover/article:opacity-100" />
          </div>
          <HeadingTag className={textClassNames}>{title}</HeadingTag>
          {!isSkyBox && showReactions && (
            <div className="flex items-center gap-x-md">
              <div className="flex items-center gap-x-xs">
                <ReactionIcon />
                <span className="text-16 font-medium leading-1.2">
                  {emojiCount}
                </span>
              </div>
              <div className="flex items-center gap-x-xs">
                <IcComment className="size-icon-lg" />
                <span className="text-16 font-medium leading-1.2">
                  {reactionCounts?.comment}
                </span>
              </div>
            </div>
          )}
        </div>
      </div>
    </a>
  );
}
