import {
  SOCIAL_EMBED_HEIGHT,
  SOCIAL_EMBED_WIDTH,
  TWITTER_SDK,
} from "@app/config/embed";
import { useWindowSize } from "@app/hooks/useWindowSize/useWindowSize";
import WrapperContainer from "@elements/Embeds/helper/WrapperContainer";
import { useScript } from "@sphtech/web2-core/hooks";

export type XEmbedCustomProps = {
  url: string;
  className?: string;
};
export default function XEmbedCustom({
  url,
  className = "",
}: XEmbedCustomProps): React.ReactElement {
  useScript({ id: "twitter-embed-script", src: TWITTER_SDK, async: true });
  const { isScreenMD } = useWindowSize();
  const twitterUrlValues = url.split("twitter.com/")[1]?.split("/");

  // Check the length of pathSegments
  if (twitterUrlValues.length == 1) {
    const xUsername = twitterUrlValues[0];
    return (
      <WrapperContainer className={className}>
        <blockquote className="twitter-tweet">
          <a
            className="twitter-timeline"
            href={url}
            data-tweet-limit="1"
            data-width={isScreenMD ? SOCIAL_EMBED_WIDTH : "100%"}
            data-height={SOCIAL_EMBED_HEIGHT}
          >
            Tweets by @{xUsername}
          </a>
        </blockquote>
      </WrapperContainer>
    );
  } else {
    return (
      <WrapperContainer className={className}>
        <blockquote
          className="twitter-tweet"
          data-width={isScreenMD ? SOCIAL_EMBED_WIDTH : "100%"}
          data-height={SOCIAL_EMBED_HEIGHT}
        >
          <p lang="en" dir="ltr">
            Check out this tweet! <a href={url}>link</a>
          </p>
        </blockquote>
      </WrapperContainer>
    );
  }
}
