import LogoWhite from "@app/assets/logo/logo-white.svg";
import { FOOTER_LINKS } from "@app/config/footer";
import { SOCIAL_LINKS } from "@app/config/social";
import Navbar from "@components/Blocks/Navbar/Navbar";
import InternalLink from "@elements/InternalLink/InternalLink";
import SocialIconLink from "@elements/SocialIconLink/SocialIconLink";
import type { ReactElement } from "react";
import { Link } from "react-router-dom";

export default function Footer(): ReactElement {
  const year = new Date().getFullYear();

  return (
    <footer className="w-full bg-primary-500 px-md pb-3xl pt-lg lg:px-0 lg:pt-2xl">
      <div className="responsive flex flex-col gap-y-lg px-sm text-white">
        <div className="flex flex-col items-center lg:flex-row lg:justify-between">
          <InternalLink to="/">
            <LogoWhite />
          </InternalLink>
          <Navbar variant="footer" />
        </div>
        <ul className="flex w-full flex-wrap items-center justify-center gap-y-sm text-nowrap font-tertiery text-12 font-semibold leading-1.5 lg:justify-between">
          <ul className="flex w-full justify-center gap-x-sm self-center text-white lg:w-fit">
            {(Object.keys(SOCIAL_LINKS) as (keyof typeof SOCIAL_LINKS)[]).map(
              (key) => (
                <li key={key}>
                  <SocialIconLink
                    variant={key}
                    iconClassName="size-[30px] lg:size-icon-lg"
                  />
                </li>
              ),
            )}
          </ul>
          {FOOTER_LINKS.map((item) => (
            <li key={item.label}>
              {item.isExternal ? (
                <a
                  href={item.url}
                  target="_blank"
                  rel="noreferrer"
                  className="block p-sm text-14 leading-1 hover:bg-primary-600 lg:text-12"
                >
                  {item.label}
                </a>
              ) : (
                <Link
                  to={item.url}
                  className="block p-sm text-14 leading-1 hover:bg-primary-600 lg:text-12"
                >
                  {item.label}
                </Link>
              )}
            </li>
          ))}
        </ul>
        <p className="text-center font-secondary text-12 leading-1.5">
          {`Published by SPH Media Limited, Co. Regn. No. 202120748H. Copyright © ${year} SPH Media Limited. All rights reserved.`}
        </p>
      </div>
    </footer>
  );
}
