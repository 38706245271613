import type { PageMetaDataValues } from "@app/types/PageMetaData";
import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";

export type AuthorMetaDataProps = {
  authorAlias: string;
  authorDesignation?: string;
};

export function AuthorMetaData({
  authorAlias,
  authorDesignation,
}: AuthorMetaDataProps): PageMetaDataValues {
  const authorSlug = authorAlias.replace(" ", "-").toLowerCase();
  const metaTags: MetaTagsProps = {
    title: `Get Latest News & Top Stories by ${authorAlias} — The New Paper`,
    description: `Read the latest news and top stories by ${authorAlias}, covering the latest in Sports, Lifestyle, and Entertainment - ${authorDesignation} at The New Paper`,
    slug: `author/${authorSlug}`,
  };

  const gaData: GADataProps = {
    level2: "miscellaneous",
    title: `Author_Index`,
    section: "miscellaneous",
    contentcat: "1",
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
