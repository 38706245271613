import { ARTICLE_BODY_IMAGE } from "@app/config/imageresponsive";
import RelatedStoryList from "@components/Layouts/RelatedStoryList/RelatedStoryList";
import Advertisement from "@elements/Advertisement/Advertisement";
import Embed from "@elements/Embeds/Embed";
import { Image, ImageCrop } from "@elements/ResponsiveImage/Image";
import Typography from "@elements/Typography/Typography";
import type { ArticleElement, ProcessedArticle } from "@typings/OpenSearch";
import { Fragment, type ReactElement } from "react";

export type ArticleBodyProps = {
  elements: ArticleElement[];
  relatedStoriesArticles: ProcessedArticle[];
  pageName: string;
};

export default function ArticleBody({
  elements,
  relatedStoriesArticles,
  pageName,
}: ArticleBodyProps): ReactElement {
  let paragraphCount = 0;

  return (
    <Fragment>
      {elements.map((e, i) => {
        const results = [];

        // Show Ads after 3rd and 6th paragraphs
        if (paragraphCount === 3 || paragraphCount === 6) {
          const adVariant = paragraphCount === 3 ? "imu1" : "midarticlespecial";
          results.push(
            <div
              key={`ad-${adVariant}`}
              className="relative clear-both -mx-lg flex justify-center bg-grayscale-100"
            >
              <Advertisement
                variant={adVariant}
                pageName={pageName}
                rootClassName="my-md"
              />
            </div>,
          );
        }

        // Show Related Stories after 5th paragraph
        if (paragraphCount === 5 && relatedStoriesArticles.length > 0) {
          results.push(
            <div
              key="related-story-segment"
              className="-mx-sm my-md lg:mx-auto lg:w-[80%]"
            >
              <RelatedStoryList
                variant="single"
                articles={relatedStoriesArticles.slice(0, 1)}
                hasMore={relatedStoriesArticles.length > 1}
              />
            </div>,
          );
        }

        if (e.type === "h2") {
          const sectionheader = e.fields.find((f) => f.name === "h2");
          if (sectionheader) {
            results.push(
              <Typography
                key={i}
                variant="section-header"
                text={sectionheader.value}
                annotations={sectionheader.annotations}
              />,
            );
          }
        } else if (e.type === "h3") {
          const subsectionheader = e.fields.find((f) => f.name === "h3");
          if (subsectionheader) {
            results.push(
              <Typography
                key={i}
                variant="subsection-header"
                text={subsectionheader.value}
                annotations={subsectionheader.annotations}
              />,
            );
          }
        } else if (e.type === "paragraph") {
          const standfirst = e.fields.find((f) => f.name === "standfirst");
          const paragraph = e.fields.find((f) => f.name === "paragraph");
          if (standfirst) {
            paragraphCount++;
            results.push(
              <Typography
                key={i}
                variant="standfirst"
                text={standfirst.value}
                annotations={standfirst.annotations}
              />,
            );
          }
          if (paragraph) {
            paragraphCount++;
            results.push(
              <Typography
                key={i}
                variant="paragraph"
                text={paragraph.value}
                annotations={paragraph.annotations}
              />,
            );
          }
        } else if (e.type === "blockquote") {
          const field = e.fields.find((f) => f.name === "paragraph");
          if (field) {
            paragraphCount++;
            results.push(
              <Typography
                key={i}
                variant="blockquote"
                text={field.value}
                annotations={field.annotations}
              />,
            );
          }
        } else if (e.type === "list") {
          const ul = e.fields.find((f) => f.name === "ul");
          const ol = e.fields.find((f) => f.name === "ol");
          if (ul && ul.children) {
            paragraphCount++;
            results.push(
              <ul
                key={i}
                className="list-inside list-disc font-secondary text-grayscale-800"
              >
                {ul.children.map((f, j) => (
                  <li key={`${i}-${j}`}>
                    <Typography text={f.value} annotations={f.annotations} />
                  </li>
                ))}
              </ul>,
            );
          }
          if (ol && ol.children) {
            paragraphCount++;
            results.push(
              <ol
                key={i}
                className="list-inside list-decimal font-secondary text-grayscale-800"
              >
                {ol.children.map((f, j) => (
                  <li key={`${i}-${j}`}>
                    <Typography text={f.value} annotations={f.annotations} />
                  </li>
                ))}
              </ol>,
            );
          }
        } else if (e.type === "image") {
          const image: Record<string, string> = {};
          e.fields.forEach((f) => {
            image[f.name] = f.value;
          });

          paragraphCount++;
          results.push(
            <div key={i} className="article-image -mx-lg my-md">
              <figure>
                <Image
                  src={image.caasUrl}
                  alt={image.altText}
                  width={ARTICLE_BODY_IMAGE.width}
                  height={ARTICLE_BODY_IMAGE.height}
                  imageCrop={ImageCrop.PROPOTIONAL}
                  loading="eager"
                />
                {(image.caption || image.credit) && (
                  <figcaption id="figcaption">
                    {image.caption && <span>{image.caption}&nbsp;</span>}
                    {image.credit && <span>{image.credit}</span>}
                  </figcaption>
                )}
              </figure>
            </div>,
          );
        } else if (e.type === "div") {
          const field = e.fields.find((f) => f.name === "div");
          if (field) {
            paragraphCount++;
            results.push(
              <div
                key={i}
                className="relative -mx-lg my-md flex justify-center"
                dangerouslySetInnerHTML={{ __html: field.value }}
              />,
            );
          }
        } else if (e.type === "embed") {
          const field = e.fields[0] || "";

          let customClassName = "w-full my-lg";
          if (field.name === "youtube" || field.name === "brightcove") {
            customClassName = "video-container";
          }

          if (field.name && field.value) {
            paragraphCount++;
            results.push(
              <Fragment key={i}>
                <Embed
                  platform={field.name}
                  url={field.value}
                  className={customClassName}
                />
              </Fragment>,
            );
          }
        } else if (e.type === "external_link") {
          const field = e.fields[0] || "";

          if (field.value) {
            const fieldUrl = field.value || "#";
            const fieldText = field.name || fieldUrl;

            paragraphCount++;
            results.push(
              <a
                className="text-primary-500 hover:underline"
                rel="noreferrer"
                href={fieldUrl}
                target="_blank"
              >
                {fieldText}
              </a>,
            );
          }
        } else if (
          e.type &&
          !["headline", "subheadline", "slug"].includes(e.type)
        ) {
          paragraphCount++;
          results.push(
            <details key={i} className="max-w-[60vw] bg-yellow-50">
              <summary>
                <code>Unimplemented block: {e.type}</code>
              </summary>
              <pre className="overflow-auto">{JSON.stringify(e, null, 2)}</pre>
            </details>,
          );
        }

        return results;
      })}
    </Fragment>
  );
}
