import {
  FB_APP_ID,
  FB_PAGES,
  MEDIA_ALT_TEXT,
  MEDIA_CAAS_URL,
  MEDIA_CAPTION,
  MEDIA_CREDIT,
  MEDIA_HEIGHT,
  MEDIA_ID,
  MEDIA_MIMETYPE,
  MEDIA_TITLE_TEXT,
  MEDIA_WIDTH,
  OG_SITE_NAME,
  ROBOTS,
  TWITTER_CARD,
  TWITTER_SITE,
} from "@app/config/metatag";
import { PRD_BASE_URL } from "@helpers/getEnvVariables";
import { removeLeadingSlash } from "@helpers/utils";
import { ArticleMedia } from "@typings/OpenSearch";
import { Helmet } from "react-helmet-async";

export type MetaTagsProps = {
  title: string;
  description?: string;
  slug: string;
  ogType?: string;
  ogTitle?: string;
  ogDescription?: string;
  image?: ArticleMedia;
  publishedTime?: string;
  modifiedTime?: string;
  keywords?: string[];
  isArticlePage?: boolean;
  robotsContent?: string;
  customCanonicalUrl?: string;
  twitterCardContent?: string;
  jsonLd?: object;
};

export default function MetaTags({
  title,
  description,
  slug,
  ogType,
  ogTitle,
  ogDescription,
  image,
  publishedTime,
  modifiedTime,
  keywords = [],
  isArticlePage = false,
  robotsContent,
  customCanonicalUrl,
  twitterCardContent,
  jsonLd,
}: MetaTagsProps) {
  const canonicalUrl: string =
    PRD_BASE_URL + (slug === "home" ? "" : removeLeadingSlash(slug));

  // Assign a default image if `image` is undefined or empty
  const defaultImage = {
    id: MEDIA_ID,
    mimeType: MEDIA_MIMETYPE,
    altText: MEDIA_ALT_TEXT,
    titleText: MEDIA_TITLE_TEXT,
    caasUrl: MEDIA_CAAS_URL,
    caption: MEDIA_CAPTION,
    credit: MEDIA_CREDIT,
  } as ArticleMedia;

  const finalImage = image || defaultImage;

  return (
    <Helmet>
      <title>{title}</title>
      {/* Search Engine tags */}
      <meta name="robots" content={robotsContent || ROBOTS} />
      <link rel="canonical" href={customCanonicalUrl || canonicalUrl} />
      {description && (
        <meta
          name="description"
          content={description}
          data-testid="meta-description"
        />
      )}
      {keywords.length > 0 && (
        <meta name="keywords" content={keywords.join(", ")} />
      )}

      {/* Open Graph tags */}
      <meta property="og:site_name" content={OG_SITE_NAME} />
      <meta property="og:url" content={canonicalUrl} />
      <meta property="og:title" content={ogTitle || title} />
      {ogType && <meta property="og:type" content={ogType} />}
      {description && (
        <meta
          property="og:description"
          content={ogDescription || description}
        />
      )}
      <meta property="og:image" content={finalImage.caasUrl} />
      <meta property="og:image:url" content={finalImage.caasUrl} />
      <meta property="og:image:secure_url" content={finalImage.caasUrl} />
      <meta property="og:image:width" content={`${MEDIA_WIDTH}`} />
      <meta property="og:image:height" content={`${MEDIA_HEIGHT}`} />
      <link rel="image_src" href={finalImage.caasUrl} />
      {modifiedTime && (
        <meta property="og:updated_time" content={modifiedTime} />
      )}

      {/* Dcterms tags */}
      {isArticlePage && <meta name="dcterms.title" content={title} />}
      {isArticlePage && <meta name="dcterms.creator" content="cue" />}
      {isArticlePage && description && (
        <meta name="dcterms.description" content={description} />
      )}
      {isArticlePage && <meta name="dcterms.date" content={publishedTime} />}
      {isArticlePage && <meta name="dcterms.type" content="Text" />}
      {isArticlePage && <meta name="dcterms.format" content="text/html" />}
      {isArticlePage && (
        <meta name="dcterms.identifier" content={canonicalUrl} />
      )}

      {/* Article tags */}
      {isArticlePage && (
        <meta property="article:published_time" content={publishedTime} />
      )}
      {isArticlePage && (
        <meta property="article:modified_time" content={modifiedTime} />
      )}

      {/* Twitter tags */}
      <meta name="twitter:card" content={twitterCardContent || TWITTER_CARD} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:url" content={canonicalUrl} />
      <meta name="twitter:image" content={finalImage.caasUrl} />
      {description && <meta name="twitter:description" content={description} />}
      <meta name="twitter:site" content={TWITTER_SITE} />

      {/* App links metadata */}
      <meta property="fb:pages" content={FB_PAGES} />
      <meta property="fb:app_id" content={FB_APP_ID} />

      {jsonLd ? (
        <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      ) : null}
    </Helmet>
  );
}
