import StatusHandler from "@pages/StatusHandler/StatusHandler";
import {
  CustomContext,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";

import { ResponsePayload } from "./StoryQueue.server";

export default function StoryQueue() {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<ResponsePayload, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;

  return <StatusHandler response={dataLoaderResponse} />;
}
