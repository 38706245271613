import TnpLogo from "@app/assets/logo/tnp-logo.png";

export const OG_SITE_NAME = "The New Paper";
export const TWITTER_CARD = "summary_large_image";
export const TWITTER_SITE = "@thenewpaper";
export const ROBOTS =
  "index, follow, notranslate, max-image-preview:large, max-snippet:-1";

export const FB_PAGES = "39533052294";
export const FB_APP_ID = "1377487789239737";

export const MEDIA_ID = "1";
export const MEDIA_MIMETYPE = "image/png";
export const MEDIA_ALT_TEXT = "The New Paper logo";
export const MEDIA_TITLE_TEXT = "The New Paper logo";
export const MEDIA_CAAS_URL = TnpLogo;
export const MEDIA_CAPTION = "The New Paper logo";
export const MEDIA_CREDIT = "The New Paper";
export const MEDIA_WIDTH = 411;
export const MEDIA_HEIGHT = 216;

export const MAX_TITLE_LENGTH = 70;
export const MAX_DESCRIPTION_LENGTH = 160;
