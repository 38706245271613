import cx from "classnames";
import type { ReactElement } from "react";

export type SectionHeaderProps = {
  page: "home" | "article";
  title: string;
};

export default function SectionHeader({
  page,
  title,
}: SectionHeaderProps): ReactElement {
  const classNames = cx("p-sm font-secondary leading-1.2", {
    "text-34 text-primary-500": page === "home",
    "text-24 text-center": page === "article",
  });

  return (
    <h2 className={classNames}>
      {title.split(" ").map((word, index) => {
        if (index % 2 === 0) {
          return (
            <span key={index} className="font-semibold">
              {word}
            </span>
          );
        }
        return (
          <span key={index} className="font-extrabold italic">
            {word}
          </span>
        );
      })}
    </h2>
  );
}
