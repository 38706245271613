import Logo from "@app/assets/logo/logo.svg";
import { BUTTON_TITLES } from "@app/config/constants";
import Navbar from "@components/Blocks/Navbar/Navbar";
import SearchInput from "@components/Blocks/SearchInput/SearchInput";
import InternalLink from "@elements/InternalLink/InternalLink";
import IcCross from "@icons/cross.svg";
import IcMenu from "@icons/menu.svg";
import cx from "classnames";
import { type ReactElement, useState } from "react";

export default function Header(): ReactElement {
  const [isMenuToggled, setIsMenuToggled] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const headerClassNames = cx(
    "group/header sticky top-0 z-20 flex w-full flex-col bg-white shadow-header lg:shadow-none",
    {
      expanded: isMenuToggled,
    },
  );

  const handleMenuToggle = () => {
    setIsMenuToggled((isMenuToggled) => !isMenuToggled);
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
  };

  return (
    <header className={headerClassNames}>
      <div className="responsive flex w-full justify-between px-sm lg:items-center lg:justify-center">
        <div className="flex shrink-0 items-center">
          <InternalLink to="/">
            <Logo className="h-[68px] w-[198px] lg:h-full lg:w-auto" />
          </InternalLink>
        </div>
        <div className="hidden flex-1 justify-between gap-x-sm lg:flex">
          <Navbar variant="header" />
          <SearchInput
            variant="header-desktop"
            placeholder="Search for"
            value={searchQuery}
            onChange={handleSearchChange}
          />
        </div>
        <div className="flex gap-x-sm">
          <div className="-ml-xs flex items-center px-xs lg:hidden">
            {isMenuToggled ? (
              <button
                className="flex size-[30px] items-center justify-center text-primary-500"
                title={BUTTON_TITLES.headerMenuCloseToggle}
                onClick={handleMenuToggle}
              >
                <IcCross className="size-icon-lg" />
              </button>
            ) : (
              <button
                className="flex size-[30px] items-center justify-center text-primary-500"
                title={BUTTON_TITLES.headerMenuOpenToggle}
                onClick={handleMenuToggle}
              >
                <IcMenu className="size-icon-lg" />
              </button>
            )}
          </div>
        </div>
      </div>
      <div className="grid grid-rows-[0fr] overflow-hidden transition-[grid-template-rows] duration-300 group-[.expanded]/header:grid-rows-[1fr] lg:hidden">
        <div className="min-h-0 opacity-0 transition-[opacity] duration-300 group-[.expanded]/header:opacity-100">
          <div className="flex min-h-0 flex-col gap-y-lg border-b-8 border-primary-500 px-2xl py-lg">
            <SearchInput
              variant="header-mobile"
              placeholder="Search for"
              value={searchQuery}
              onChange={handleSearchChange}
            />
            <Navbar variant="menu" />
          </div>
        </div>
      </div>
    </header>
  );
}
