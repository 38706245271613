import { REACTION_CONFIG } from "@app/config/sharing";
import type { ReactElement } from "react";

import type { InteractionToolbarProps } from "./InteractionToolbar";

type ReactionListProps = {
  counts: InteractionToolbarProps["reactionCounts"];
};

export default function ReactionList({
  counts,
}: ReactionListProps): ReactElement {
  return (
    <ul className="flex w-full justify-between gap-x-xl md:w-fit md:justify-normal">
      {(Object.keys(REACTION_CONFIG) as (keyof typeof REACTION_CONFIG)[]).map(
        (key) => (
          <li key={key} className="flex flex-col items-center gap-y-xs">
            <button>
              <img
                className="size-icon-lg"
                src={REACTION_CONFIG[key].imageUrl}
                alt={REACTION_CONFIG[key].imageAlt}
              />
            </button>
            <span className="font-secondary text-16 font-medium leading-1.2">
              {counts[key]}
            </span>
          </li>
        ),
      )}
    </ul>
  );
}
