import { BRIGHTCOVE_ACCOUNT_ID, BRIGHTCOVE_PLAYER_ID } from "@app/config/embed";
import BrightcovePlayer from "@brightcove/react-player-loader";
import WrapperContainer from "@elements/Embeds/helper/WrapperContainer";

const extractBrightcoveDetails = (url: string) => {
  const regex =
    /players\.brightcove\.net\/(\d+)\/([a-zA-Z0-9]+)_.*\?videoId=(\d+)/;
  const match = url.match(regex);

  if (match) {
    const accountId = match[1];
    const playerId = match[2];
    const videoId = match[3];
    return { accountId, playerId, videoId };
  }

  console.error(`Error rendering Brightcove video with url: ${url}`);
  return null;
};

type BrightcoveEmbedCustomProps = {
  url: string;
  className?: string;
};
export default function BrightcoveEmbedCustom({
  url,
  className = "",
}: BrightcoveEmbedCustomProps): React.ReactElement {
  const bcDetails = extractBrightcoveDetails(url);
  const accountId = bcDetails?.accountId || BRIGHTCOVE_ACCOUNT_ID;
  const playerId = bcDetails?.playerId || BRIGHTCOVE_PLAYER_ID;
  const videoId = bcDetails?.videoId || "";

  return (
    <>
      <style>
        {`
        .video-js {
          width: 100%;
          height: 100%;
        }
      `}
      </style>
      <WrapperContainer className={className}>
        <BrightcovePlayer
          accountId={accountId}
          playerId={playerId}
          videoId={videoId}
          attrs={{ style: { width: "100%" } }}
        />
      </WrapperContainer>
    </>
  );
}
