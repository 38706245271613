import { BUTTON_TITLES } from "@app/config/constants";
import { SEARCH_SORT_DEFAULT } from "@app/config/search";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getWindowHref } from "@helpers/utils";
import IcCrossCircle from "@icons/cross-circle.svg";
import IcCrossCircleFilled from "@icons/cross-circle-filled.svg";
import IcSearch from "@icons/search.svg";
import cx from "classnames";
import { type ReactElement, useEffect, useRef, useState } from "react";

export type SearchInputProps = {
  variant: "header-desktop" | "header-mobile" | "page";
  placeholder?: string;
  value?: string;
  onChange?(event: React.ChangeEvent<HTMLInputElement>): void;
  onReset?(): void;
  onPressEnter?(): void;
  preventDefaultSubmit?: boolean;
};

export default function SearchInput({
  variant,
  placeholder,
  value,
  onChange,
  onReset,
  onPressEnter,
  preventDefaultSubmit = false,
}: SearchInputProps): ReactElement {
  const formRef = useRef<HTMLFormElement>(null);
  const inputRef = useRef<HTMLInputElement>(null); // Ref for the input field
  const [isSearchExpanded, setIsSearchExpanded] = useState(
    variant === "header-mobile",
  );

  const formClassNames = cx("flex", {
    "flex-1 justify-end": variant !== "page",
  });
  const wrapperClassNames = cx(
    "relative flex items-center overflow-hidden border",
    {
      "rounded-full border-primary-500 transition-[width] duration-300":
        variant !== "page",
      "py-sm border-black": variant === "page",
      "w-[37px]": variant === "header-desktop" && !isSearchExpanded,
      "w-[--search-input-width]":
        variant === "header-desktop" && isSearchExpanded,
      grow: variant !== "header-desktop",
    },
  );
  const inputClassNames = cx("w-full py-xs outline-none", {
    "text-16 font-medium mr-xl max-w-[calc(100%-32px)] placeholder:text-grayscale-700":
      variant !== "page",
    "text-20 font-semibold leading-1.2 placeholder:text-disabled":
      variant === "page",
  });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      onPressEnter?.();
    }
  };

  const sendGaEvent = useGTMDispatch();

  const handleSearchExpand = () => {
    setIsSearchExpanded((isSearchExpanded) => !isSearchExpanded);

    const form = formRef.current as HTMLFormElement;
    if (!form.getAttribute("style")) {
      handleResize();
    }

    if (process.env.NODE_ENV !== "test") {
      sendGaEvent({
        event: "custom_event",
        clickCategory: "internal search",
        clickAction: "click",
        clickLabel: getWindowHref(),
      });
    }
  };

  const handleResize = () => {
    const form = formRef.current as HTMLFormElement;
    const width = form.clientWidth;
    form.setAttribute("style", `--search-input-width:${width}px`);
  };

  // Focus the input when the search box is expanded
  useEffect(() => {
    if (isSearchExpanded && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSearchExpanded]);

  useEffect(() => {
    globalThis.addEventListener("resize", handleResize);

    return () => {
      globalThis.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (variant === "page" && inputRef.current) {
      inputRef.current.focus();
    }
  }, [variant]);

  return (
    <form
      className={formClassNames}
      role="search"
      method="get"
      action="/sitesearch/"
      ref={formRef}
      onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
        if (preventDefaultSubmit) {
          event.preventDefault();
        }
      }}
    >
      <div className={wrapperClassNames}>
        <button
          className={cx("group/button p-sm transition-colors", {
            "hover:bg-primary-500 hover:text-white":
              variant === "header-desktop" && !isSearchExpanded,
          })}
          type="button"
          title={
            variant === "header-desktop" && !isSearchExpanded
              ? BUTTON_TITLES.searchInputExpand
              : undefined
          }
          onClick={
            variant === "header-desktop" && !isSearchExpanded
              ? handleSearchExpand
              : undefined
          }
        >
          <IcSearch
            className={cx("transition-colors", {
              "size-icon-md fill-primary-500": variant !== "page",
              "size-icon-lg": variant === "page",
              "group-hover/button:fill-white":
                variant === "header-desktop" && !isSearchExpanded,
            })}
          />
        </button>
        <input
          ref={inputRef}
          className={inputClassNames}
          type="text"
          name="key"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyDown}
        />
        {variant !== "page" && (
          <input type="hidden" name="sort" value={SEARCH_SORT_DEFAULT} />
        )}
        {variant === "header-desktop" && isSearchExpanded && (
          <button
            className="absolute right-0 top-0 p-sm text-primary-500"
            type="button"
            title={BUTTON_TITLES.searchInputCollapse}
            onClick={handleSearchExpand}
          >
            <IcCrossCircleFilled className="size-icon-md" />
          </button>
        )}
        {variant === "page" && (
          <button
            className="mx-sm text-black"
            type="reset"
            onClick={() => {
              onReset?.();
              inputRef.current?.focus();
            }}
          >
            <IcCrossCircle className="size-icon-lg" />
          </button>
        )}
      </div>
    </form>
  );
}
