import { ProcessedTopArticleResponse } from "@app/caas/transformer/getProcessedTopArticles";
import { ARTICLE_COUNT } from "@app/config/article";
import { isArrayWithElements } from "@app/helpers/utils";
import useSwrApi from "@app/hooks/useSwrApi/useSwrApi";
import ArticleList from "@components/Layouts/ArticleList/ArticleList";
import Advertisement from "@elements/Advertisement/Advertisement";
import Button from "@elements/Button/Button";
import InternalLink from "@elements/InternalLink/InternalLink";
import SectionHeader from "@elements/SectionHeader/SectionHeader";
import BasePage, { type BasePageProps } from "@pages/BasePage";
import StatusHandler from "@pages/StatusHandler/StatusHandler";
import {
  type CustomContext,
  ResponseType,
  type TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import { type ReactElement } from "react";
import { Helmet } from "react-helmet-async";

import type { ResponsePayload } from "./Home.server";
import HomeMetaData from "./HomeMetaData";

// TODO: to be removed
type HomePageProps = {
  showTikTok?: boolean;
  showMostTalkedAbout?: boolean;
};

export default function HomePage({
  showTikTok,
  showMostTalkedAbout,
}: HomePageProps): ReactElement {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<ResponsePayload, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;

  const { data: topReads, error } = useSwrApi<ProcessedTopArticleResponse[]>({
    endpoint: `top-articles`,
  });

  if (dataLoaderResponse.type !== ResponseType.SUCCESS) {
    return <StatusHandler response={dataLoaderResponse} />;
  }

  const { featuredArticles, mostTalkedAboutArticles } =
    dataLoaderResponse.payload;

  const adsSectionName = "home";
  const { gaData, metaTags } = HomeMetaData(adsSectionName);
  const pageName = "homepage";
  const basePageProps: BasePageProps = {
    gaData,
    metaTags: metaTags,
    pageName: pageName,
  };

  const featuredArticleIds = new Set(
    featuredArticles?.map((article) => article.id),
  );

  const nonDuplicateTopReads =
    isArrayWithElements(topReads) &&
    topReads
      .filter((article) => !featuredArticleIds.has(article.id))
      .slice(0, ARTICLE_COUNT.home.topReads);

  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="WI7jtvVBp4EieGafVsOKcPP1xs1U5SPNHFVbzlMlPjc"
        />
      </Helmet>
      <BasePage responsive={false} {...basePageProps}>
        <div className="flex flex-col gap-y-lg">
          {!error && (
            <section className="flex min-h-[200px] flex-col gap-y-sm bg-primary-100 py-md">
              <div className="responsive">
                <SectionHeader page="home" title="Top Reads" />
                <div className="overflow-x-auto">
                  {isArrayWithElements(nonDuplicateTopReads) && (
                    <ArticleList
                      variant="home-skybox"
                      articles={nonDuplicateTopReads}
                    />
                  )}
                </div>
              </div>
            </section>
          )}

          <section className="responsive flex flex-col lg:flex-row">
            {isArrayWithElements(featuredArticles) && (
              <>
                <ArticleList
                  variant="home-top-left"
                  articles={featuredArticles.slice(0, 1)}
                />
                <div className="flex flex-col">
                  <ArticleList
                    variant="home-top-right"
                    articles={featuredArticles.slice(1)}
                  />
                  <div className="mt-md justify-items-center">
                    <InternalLink
                      to="/news"
                      className="align-center flex h-fit w-fit justify-center border-b-3 border-l border-r-3 border-t border-primary-500 bg-white p-sm font-secondary text-16 font-bold uppercase leading-1 text-primary-500 hover:border-primary-700 hover:bg-primary-500 hover:text-white"
                    >
                      MORE STORIES
                    </InternalLink>
                  </div>
                </div>
              </>
            )}
          </section>
          {showTikTok && isArrayWithElements(showTikTok) && (
            <section className="bg-black py-md">
              <div className="responsive grid grid-cols-1 gap-md md:grid-cols-2 lg:grid-cols-[3fr,1fr] lg:grid-rows-[41px_auto]">
                <div className="mx-sm flex items-center justify-between md:col-span-2 lg:col-span-1">
                  <SectionHeader page="home" title="Get Social" />
                  <Button variant="primary">View more on TikTok</Button>
                </div>
                <div className="flex h-full items-center justify-center bg-primary-50 lg:row-start-2">
                  Video carousel
                </div>
                <aside className="flex justify-center px-sm lg:row-span-2">
                  <Advertisement variant="imu2" pageName={pageName} />
                </aside>
              </div>
            </section>
          )}
          {showMostTalkedAbout &&
            isArrayWithElements(mostTalkedAboutArticles) && (
              <section className="responsive">
                <SectionHeader page="home" title="Most Talked About" />
                <div className="flex flex-col gap-lg lg:flex-row">
                  <div className="flex basis-3/4 flex-col md:flex-row">
                    <div className="flex basis-2/3 flex-col">
                      <ArticleList
                        variant="home-bottom-left-1"
                        articles={mostTalkedAboutArticles.slice(0, 1)}
                      />
                      <ArticleList
                        variant="home-bottom-left-2"
                        articles={mostTalkedAboutArticles.slice(1, 3)}
                      />
                      <ArticleList
                        variant="home-bottom-left-3"
                        articles={mostTalkedAboutArticles.slice(3, 5)}
                      />
                    </div>
                    <div className="flex basis-1/3 flex-col">
                      <ArticleList
                        variant="home-bottom-right"
                        articles={mostTalkedAboutArticles.slice(5, 8)}
                      />
                    </div>
                  </div>
                  <aside className="flex min-w-[300px] basis-1/4 justify-center bg-grayscale-100 lg:bg-white">
                    <Advertisement
                      variant="imu3"
                      pageName={pageName}
                      isSticky
                    />
                  </aside>
                </div>
              </section>
            )}
        </div>
      </BasePage>
    </>
  );
}
