import { ENVIRONMENT } from "@app/helpers/getEnvVariables";
import { AdTargetingType, AdTypeEnum } from "@typings/Ads";

export const ADS_DOMAIN: string =
  ENVIRONMENT == "prd"
    ? "https://adtag.sphdigital.com"
    : "https://adtag-stg.sphdigital.com";

export const ADS_PREFIX = "/5908/tnp";

/**
 * Configuration for Google Publisher Tag (GPT) slot sizes
 * Update new sizes here when needed e.g. midarticlespecial
 * @see https://docs.google.com/spreadsheets/d/1c7vdPtKMYJ36lHdDGpdNR5hru0bvIe693S2UkoDjVAo/edit?gid=915581497#gid=915581497
 */
export const adSlotSizes: Record<string, googletag.GeneralSize> = {
  lb1: [
    [970, 90],
    [728, 90],
    [970, 250],
    [1, 1],
    [320, 50],
    [320, 100],
    [320, 250],
    ["fluid"],
  ],
  lb2: [[928, 90], [320, 100], ["fluid"]],
  imu1: [[300, 250], [1, 1], ["fluid"]],
  imu2: [
    [300, 250],
    [300, 600],
  ],
  midarticlespecial: [[300, 250], [1, 1], ["fluid"]],
  prestitial: [1, 1],
  catfish: [1, 1],
  abm: [1, 1],
  bn1: [[728, 90], [320, 50], [320, 100], [1, 1], ["fluid"]],
};

export const adSizeMappings: Partial<
  Record<AdTypeEnum, googletag.SizeMappingArray>
> = {
  lb1: [
    [
      [1024, 0],
      [
        [970, 90],
        [728, 90],
        [970, 250],
        [1, 1],
      ],
    ],
    [
      [740, 0],
      [
        [728, 90],
        [1, 1],
      ],
    ],
    [
      [320, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
    [
      [0, 0],
      [[320, 50], [320, 100], [320, 250], "fluid"],
    ],
  ],
  lb2: [
    [
      [1024, 0], // For desktop screens
      [
        [728, 90], // Ad size for desktop
      ],
    ],
    [
      [0, 0], // For mobile devices (smaller screens)
      [
        [320, 100], // Ad size for mobile web
        "fluid", // Fluid to ensure responsiveness
      ],
    ],
  ],
  bn1: [
    [
      [1024, 0],
      [[728, 90], [1, 1], "fluid"],
    ],
    [
      [320, 0],
      [[320, 50], [320, 100], [1, 1], "fluid"],
    ],
    [
      [0, 0],
      [[320, 50], [320, 100], [1, 1], "fluid"],
    ],
  ],
};

export const adTargetings: Partial<Record<AdTypeEnum, AdTargetingType[]>> = {
  lb1: [
    { key: "pos", value: "1" },
    { key: "weight", value: "1" },
  ],
  lb2: [
    { key: "pos", value: "1" },
    { key: "weight", value: "1" },
  ],
  imu1: [
    { key: "pos", value: "1" },
    { key: "weight", value: "5" },
  ],
  imu2: [
    { key: "pos", value: "2" },
    { key: "weight", value: "6" },
  ],
  midarticlespecial: [
    { key: "pos", value: "midarticlespecial" },
    { key: "weight", value: "4" },
  ],
  prestitial: [
    { key: "pos", value: "prestitial" },
    { key: "weight", value: "3" },
  ],
  catfish: [
    { key: "pos", value: "catfish" },
    { key: "weight", value: "3" },
  ],
  abm: [
    { key: "pos", value: "abm" },
    { key: "weight", value: "3" },
  ],
  bn1: [{ key: "pos", value: "bn" }],
};
