import { ProcessedTopArticleResponse } from "@app/caas/transformer/getProcessedTopArticles";
import {
  ARTICLE_GROUP_LAYOUT,
  type ArticleGroupLayoutVariant,
} from "@app/config/article";
import { REACTION_CONFIG } from "@app/config/sharing";
import { isArrayWithElements } from "@app/helpers/utils";
import ArticleCard from "@components/Blocks/ArticleCard/ArticleCard";
import { ProcessedListingArticle } from "@typings/OpenSearch";
import cx from "classnames";
import type { ReactElement } from "react";

export type ArticleListProps = {
  variant: ArticleGroupLayoutVariant;
  articles: ProcessedListingArticle[] | ProcessedTopArticleResponse[];
};

export default function ArticleList({
  variant,
  articles,
}: ArticleListProps): ReactElement | null {
  const pageLayout = ARTICLE_GROUP_LAYOUT[variant];
  const { count, orientation } = pageLayout;
  const isSkyBox = variant === "home-skybox";
  articles = articles.slice(0, count);

  const listClassNames = cx("flex", {
    "flex-row": orientation.group.sm === "horizontal",
    "flex-col": orientation.group.sm === "vertical",
    "md:flex-row": orientation.group.md === "horizontal",
    "md:flex-col": orientation.group.md === "vertical",
    "lg:flex-row": orientation.group.lg === "horizontal",
    "lg:flex-col": orientation.group.lg === "vertical",
    "h-fit": count === 1,
  });
  const listItemClassNames = cx("flex w-full", {
    [`grow-0 basis-1/${count}`]:
      orientation.group.sm === "horizontal" && count > 1,
    [`grow-0 lg:basis-1/${count}`]:
      orientation.group.lg === "horizontal" && count > 1,
    "max-lg:min-w-[280px] border-r border-primary-500 first:pl-sm last:border-r-0 last:pr-sm lg:first:pl-0 lg:last:pr-0":
      isSkyBox,
  });

  return isArrayWithElements(articles) ? (
    <ul className={listClassNames}>
      {articles.map((article) => (
        <li key={article.id} className={listItemClassNames}>
          <ArticleCard
            layout={pageLayout}
            title={article.title}
            date={article.publishedDate}
            imageUrl={article.media[0]?.caasUrl || ""}
            url={article.url}
            referer={pageLayout.referer}
            reactionCounts={
              "reactionCounts" in article
                ? (article.reactionCounts as Record<
                    keyof typeof REACTION_CONFIG,
                    number
                  > & {
                    comment: number;
                  })
                : undefined
            }
            isSkyBox={isSkyBox}
            showReactions={false}
          />
        </li>
      ))}
    </ul>
  ) : null;
}
