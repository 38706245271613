import { TITLE_SPORTS } from "./constants";

interface NavItem {
  label: string;
  url: string;
}

export const NAV_ITEMS: NavItem[] = [
  {
    label: "News",
    url: "/news",
  },
  {
    label: TITLE_SPORTS,
    url: "/sports",
  },
  {
    label: "Entertainment",
    url: "/entertainment",
  },
  {
    label: "Lifestyle",
    url: "/lifestyle",
  },
  {
    label: "Kopitime",
    url: "/tag/kopitime",
  },
];
