interface SearchSortOpt {
  label: string;
  value: string;
}

export const SEARCH_SORT_OPT: SearchSortOpt[] = [
  {
    label: "Relevance",
    value: "relevance",
  },
  {
    label: "Latest",
    value: "latest",
  },
];

export const SEARCH_SORT_DEFAULT = SEARCH_SORT_OPT[0]?.value;
