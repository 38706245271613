import ReactionIcon from "@elements/ReactionIcon/ReactionIcon";
import IcAddReaction from "@icons/add-reaction.svg";
import IcComment from "@icons/comment.svg";
import type { ProcessedArticle } from "@typings/OpenSearch";
import cx from "classnames";
import { forwardRef, type ReactElement, useEffect, useState } from "react";

import ReactionList from "./ReactionList";
import ShareButtonList from "./ShareButtonList";

export type InteractionToolbarProps = {
  variant: "top" | "bottom" | "floating";
  reactionCounts: ProcessedArticle["reactionCounts"];
  articleTitle: string;
  articleUrl: string;
  show?: boolean;
  showReactions?: boolean;
};

const InteractionToolbar = forwardRef<HTMLDivElement, InteractionToolbarProps>(
  function InteractionToolbar(
    {
      variant,
      reactionCounts,
      articleTitle,
      articleUrl,
      show,
      // TODO: to be removed
      showReactions,
    }: InteractionToolbarProps,
    ref,
  ): ReactElement {
    const emojiCount = (
      Object.keys(reactionCounts) as (keyof typeof reactionCounts)[]
    ).reduce(
      (prev, curr) => (curr === "comment" ? prev : prev + reactionCounts[curr]),
      0,
    );

    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
      if (!show) {
        setIsExpanded(false);
      }
    }, [show]);

    useEffect(() => {
      const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
        const el = event.target as HTMLElement;
        if (
          !(
            el.classList.contains("interaction-toolbar") ||
            el.closest(".interaction-toolbar")
          )
        ) {
          setIsExpanded(false);
        }
      };

      globalThis.addEventListener("mouseup", handleOutsideClick);
      globalThis.addEventListener("touchend", handleOutsideClick);

      return () => {
        globalThis.removeEventListener("mouseup", handleOutsideClick);
        globalThis.addEventListener("touchend", handleOutsideClick);
      };
    }, []);

    if (variant === "top") {
      return (
        <div
          className="interaction-toolbar flex justify-between rounded-full bg-secondary-500 p-md text-primary-700"
          ref={ref}
        >
          <div className="flex gap-x-lg">
            <span className="text-16 font-semibold leading-1.5">
              Share this article
            </span>
            {showReactions && (
              <>
                <a className="flex items-center gap-x-sm" href="#reactions">
                  <ReactionIcon />
                  <span className="text-16 font-medium">{emojiCount}</span>
                  <IcAddReaction className="size-icon-lg" />
                </a>
                <a className="flex items-center gap-x-sm" href="#comments">
                  <IcComment className="size-icon-lg" />
                  <span className="text-16 font-medium">
                    {reactionCounts.comment}
                  </span>
                </a>
              </>
            )}
          </div>
          <ShareButtonList
            variant="full"
            dropdownPosition="bottom"
            placingPosition="top"
            articleTitle={articleTitle}
            articleUrl={articleUrl}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
          />
        </div>
      );
    }

    if (variant === "bottom") {
      return (
        <div
          className="my-sm flex flex-col items-center gap-y-md"
          ref={ref}
          id="reactions"
        >
          {showReactions && (
            <div className="flex w-full flex-col items-center gap-md rounded-3xl bg-primary-50 px-lg py-md md:w-fit md:flex-row">
              <span className="text-20 font-semibold leading-1.2">
                What do you think?
              </span>
              <ReactionList counts={reactionCounts} />
            </div>
          )}
          <div className="interaction-toolbar flex w-full items-center justify-between rounded-3xl bg-primary-50 p-md text-grayscale-800 md:w-fit md:justify-normal md:gap-x-md">
            <span className="text-16 font-semibold leading-1.2">
              Share this article
            </span>
            <ShareButtonList
              variant="full"
              dropdownPosition="bottom"
              placingPosition="bottom"
              articleTitle={articleTitle}
              articleUrl={articleUrl}
              isExpanded={isExpanded}
              setIsExpanded={setIsExpanded}
            />
          </div>
        </div>
      );
    }

    return (
      <div
        className={cx(
          "interaction-toolbar fixed bottom-xl right-0 flex gap-x-lg rounded-l-full bg-secondary-500 px-md py-sm text-primary-700 transition-transform duration-200 ease-in-out",
          {
            "translate-x-full": !show,
            "translate-x-0": show,
          },
        )}
        ref={ref}
      >
        {showReactions && (
          <div className="flex gap-x-lg">
            <a className="flex items-center gap-x-sm" href="#reactions">
              <ReactionIcon />
              <span className="text-16 font-medium">{emojiCount}</span>
              <IcAddReaction className="size-icon-lg" />
            </a>
            <a className="flex items-center gap-x-sm" href="#comments">
              <IcComment className="size-icon-lg" />
              <span className="text-16 font-medium">
                {reactionCounts.comment}
              </span>
            </a>
          </div>
        )}
        <ShareButtonList
          variant="partial"
          dropdownPosition="top"
          placingPosition="float"
          articleTitle={articleTitle}
          articleUrl={articleUrl}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
        />
      </div>
    );
  },
);

export default InteractionToolbar;
