export type IframeWrapperAttr = {
  className?: string;
};

export type IframeProps = {
  url: string;
  height?: string;
  width?: string;
  title?: string;
  className?: string;
  allowFullScreen?: boolean;
  addWrapper?: boolean;
  wrapperAttr?: IframeWrapperAttr;
};

export default function Iframe({
  url,
  title = "#NoTapis",
  className,
  allowFullScreen = false,
  addWrapper = false,
  wrapperAttr = {},
}: IframeProps) {
  const iframe = (
    <iframe
      className={className}
      src={url}
      title={title}
      allowFullScreen={allowFullScreen}
    ></iframe>
  );

  return addWrapper ? (
    <div className={wrapperAttr.className}>{iframe}</div>
  ) : (
    iframe
  );
}
