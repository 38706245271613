import useLink from "@app/hooks/useLink";
import useScriptFunction from "@app/hooks/useScriptFunction";
import {
  facebookEventScript,
  facebookNoScript,
} from "@app/providers/scripts/facebook";
import {
  crwdcntrlScript,
  crwdcntrlScriptPath,
  dnsprefetch,
  firstLoad,
  lotameScript,
  preconnect,
} from "@app/providers/scripts/lotame";
import {
  // permutiveScript,
  permutiveSphl,
} from "@components/Elements/Advertisement/scripts/permutive";
import { useScript } from "@sphtech/web2-core/hooks";

export default function HeadScriptProvider(): null {
  //Edge Permutive App
  // useScript({
  //   id: "edge-permutive-app",
  //   src: permutiveScript,
  //   async: true,
  // });

  //Permutive SPHL
  useScriptFunction({
    id: "permutive-sphl",
    value: permutiveSphl,
  });

  //Lotame
  useLink({ id: "preconnect-1", rel: "preconnect", href: preconnect });
  useLink({ id: "preconnect-2", rel: "preconnect", href: dnsprefetch });
  useLink({ id: "dnsprefetch-1", rel: "dns-prefetch", href: preconnect });
  useLink({ id: "dnsprefetch-2", rel: "dns-prefetch", href: dnsprefetch });
  useScript({
    id: "tags-crwdcntrl-script-path",
    src: crwdcntrlScriptPath,
  });
  useScript({
    id: "tags-crwdcntrl-script",
    src: crwdcntrlScript,
  });
  useScriptFunction({ id: "load-first", value: firstLoad });
  useScriptFunction({ id: "lotame-init", value: lotameScript });

  //Facebook Event Tracking
  useScriptFunction({ id: "facebook-script", value: facebookEventScript });
  useScriptFunction({
    id: "facebook-no-script",
    value: facebookNoScript,
    noscript: true,
    portion: "body",
  });

  // Optimizely for A/B Testing
  useScript({
    id: "optimizely-script",
    src: "https://cdn.optimizely.com/js/8908711038.js",
  });

  return null;
}
