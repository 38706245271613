import { TEAM_MEMBERS } from "@app/config/team";
import AuthorCard from "@components/Blocks/AuthorCard/AuthorCard";
import PageHeader from "@components/Elements/PageHeader/PageHeader";
import { PRD_BASE_HOST } from "@helpers/getEnvVariables";
import BasePage, { type BasePageProps } from "@pages/BasePage";
import type { ReactElement } from "react";

export default function TeamPage(): ReactElement {
  const basePageProps: BasePageProps = {
    gaData: {
      level2: "miscellaneous",
      title: "Team",
      section: "miscellaneous",
      contentcat: "1",
      contenttype: "listing",
    },
    metaTags: {
      title: "Meet the team - Journalists and Editors at The New Paper",
      slug: "team",
      description: `The New Paper - Get breaking news, latest Sports, Entertainment & Lifestyle News from Singapore, Asia and around the world at ${PRD_BASE_HOST}`,
    },
  };

  return (
    <BasePage {...basePageProps}>
      <section className="my-lg flex flex-col items-center">
        <PageHeader title="Our Team" />
        <div className="flex w-full flex-col gap-y-lg">
          <div className="flex flex-col gap-y-md">
            <h2 className="flex p-md font-secondary text-34 font-extrabold leading-1 text-primary-500">
              Editors
            </h2>
            <AuthorCard author={TEAM_MEMBERS[0]} showLink />
          </div>
          <div className="flex flex-col gap-y-md">
            <h2 className="flex p-md font-secondary text-34 font-extrabold leading-1 text-primary-500">
              Reporters and Contributors
            </h2>
            {TEAM_MEMBERS.slice(1).map((author) => (
              <AuthorCard key={author.id} author={author} showLink />
            ))}
          </div>
        </div>
      </section>
    </BasePage>
  );
}
