import { generateAdvertElementId } from "@elements/Advertisement/helpers";
import { AdTypeEnum } from "@typings/Ads";
import { useEffect, useRef, useState } from "react";

type advertLoadedType = {
  type: AdTypeEnum;
  id?: string;
  pageNumber?: number;
};

export function useLoadedAd({ type, id, pageNumber }: advertLoadedType) {
  const [adLoaded, setAdLoaded] = useState(false);
  const targetNodeRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    const targetNodeId = id || generateAdvertElementId(type, pageNumber);
    const callBack = () => {
      if (
        targetNodeRef.current?.children[0]?.firstElementChild?.tagName ===
        "IFRAME"
      ) {
        setAdLoaded(true);
      }
    };

    targetNodeRef.current = document.getElementById(targetNodeId);
    const observer = new MutationObserver(callBack);

    if (targetNodeRef.current) {
      observer.observe(targetNodeRef.current, {
        childList: true,
        subtree: true,
      });
    }
  }, [id, type, pageNumber, setAdLoaded]);

  return adLoaded;
}
