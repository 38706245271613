import IcQuotationMark from "@icons/quotation-mark.svg";
import { ArticleElementFieldAnnotation } from "@typings/OpenSearch";
import type { ReactNode } from "react";
import { Link } from "react-router-dom";

export type TypographyProps = {
  variant?:
    | "headline"
    | "subheadline"
    | "kicker"
    | "section-header"
    | "subsection-header"
    | "standfirst"
    | "paragraph"
    | "blockquote"
    | "substory-headline";
  text: string;
  annotations?: ArticleElementFieldAnnotation[];
};

function wrapTextWithAnnotation(
  text: ReactNode,
  annotation: ArticleElementFieldAnnotation,
): ReactNode {
  const { name, value, index } = annotation;
  switch (name) {
    case "bold":
      return value === "true" ? <b key={index}>{text}</b> : text;
    case "italic":
      return value === "true" ? <i key={index}>{text}</i> : text;
    case "underline":
      return value === "true" ? <u key={index}>{text}</u> : text;
    case "capitals":
      return value === "true" ? (
        <span key={index} className="uppercase">
          {text}
        </span>
      ) : (
        text
      );
    case "external_link":
    case "internal_link":
      return value ? (
        <Link
          key={index}
          className="text-primary-500 hover:underline"
          to={value}
          target={name === "external_link" ? "_blank" : undefined}
          rel={name === "external_link" ? "noreferrer" : undefined}
          reloadDocument={name === "internal_link"}
        >
          {text}
        </Link>
      ) : (
        text
      );
    case "newline":
      return value === "true" ? <br key={index} /> : text;
    default:
      return text;
  }
}

function getFormattedText(
  text: string,
  annotations?: ArticleElementFieldAnnotation[],
  startIndex = 0,
): React.ReactNode {
  if (!annotations || !annotations.length) {
    return text;
  }

  const results = [];
  const processedIndices = new Set<number>();
  annotations = [...annotations].sort((a, b) => a.index - b.index);

  let currentIndex = 0;
  annotations.forEach((annotation, i) => {
    const { index, length } = annotation;
    if (processedIndices.has(index)) return;

    const endIndex = index + length;
    const segment = text.slice(index - startIndex, endIndex - startIndex);
    const nestedAnnotations = annotations
      .slice(i + 1)
      .filter(
        (nestedAnnotation) =>
          nestedAnnotation.index >= index && nestedAnnotation.index < endIndex,
      );

    if (currentIndex < index - startIndex) {
      results.push(text.slice(currentIndex, index - startIndex));
      currentIndex = index - startIndex;
    }

    if (nestedAnnotations.length > 0) {
      const nestedResults = getFormattedText(segment, nestedAnnotations, index);
      results.push(wrapTextWithAnnotation(nestedResults, annotation));
    } else {
      results.push(wrapTextWithAnnotation(segment, annotation));
    }

    for (let j = index; j < endIndex; j++) {
      processedIndices.add(j);
    }

    currentIndex = endIndex - startIndex;
  });

  if (currentIndex < text.length) {
    results.push(text.slice(currentIndex));
  }

  return results;
}

export default function Typography({
  variant,
  text,
  annotations,
}: TypographyProps): ReactNode {
  switch (variant) {
    case "headline":
      return (
        <h1 className="font-primary text-32 font-semibold leading-1.2 text-grayscale-900 md:text-44">
          {text}
        </h1>
      );
    case "subheadline":
      return (
        <p className="font-secondary text-18 font-semibold italic leading-1.5 text-grayscale-800">
          {text}
        </p>
      );
    case "kicker":
      return (
        <span className="font-secondary text-14 font-bold leading-1">
          {text}
        </span>
      );
    case "section-header":
      return (
        <h2 className="mb-sm font-primary text-22 font-bold leading-33px lg:text-28 lg:leading-33.6px">
          {getFormattedText(text, annotations)}
        </h2>
      );
    case "subsection-header":
      return (
        <h3 className="mb-[-8px] font-primary text-20 font-bold leading-30px lg:text-24 lg:leading-36px">
          {getFormattedText(text, annotations)}
        </h3>
      );
    case "standfirst":
    case "paragraph":
      return (
        <p className="font-secondary text-18 font-normal leading-1.5 text-grayscale-800">
          {getFormattedText(text, annotations)}
        </p>
      );
    case "blockquote":
      return (
        <div className="relative px-xl pb-xs pt-md">
          <IcQuotationMark className="absolute left-[17px] top-0 z-[1] h-[36px] w-[42px] fill-primary-200" />
          <p className="relative z-[2] font-secondary text-20 font-bold leading-1.5 text-grayscale-800 md:text-24">
            {getFormattedText(text, annotations)}
          </p>
        </div>
      );
    case "substory-headline":
      return (
        <h2 className="font-primary text-26 font-semibold leading-1.2 text-grayscale-900 lg:text-30">
          {text}
        </h2>
      );
    default:
      return getFormattedText(text, annotations);
  }
}
