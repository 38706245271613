import { fetchApi } from "@app/helpers/fetchApi";
import type { MoreArticlesResponseBody } from "@typings/LoadMoreApi";
import type { ProcessedListingArticle } from "@typings/OpenSearch";
import { useState } from "react";

type UseLoadMoreOptions = {
  endpoint: string;
  pageSize: number;
  initialArticles: ProcessedListingArticle[];
  totalArticles: number;
};

type UseLoadMore = {
  moreArticles: ProcessedListingArticle[][];
  fetchMoreArticles: (
    requestBody: Record<string, unknown>,
    reset?: boolean,
  ) => Promise<void>;
  totalResults: number;
  hasMore: boolean;
  isLoading: boolean;
};

export function useLoadMore({
  endpoint,
  pageSize,
  initialArticles,
  totalArticles,
}: UseLoadMoreOptions): UseLoadMore {
  const [articleIds, setArticleIds] = useState(
    new Set(initialArticles.map((article) => article.id)),
  );
  const [moreArticles, setMoreArticles] = useState<ProcessedListingArticle[][]>(
    [],
  );
  const [totalResults, setTotalResults] = useState(-1);
  const [currentPage, setCurrentPage] = useState(0);
  const [hasMore, setHasMore] = useState(totalArticles > pageSize);
  const [isLoading, setIsLoading] = useState(false);

  const fetchMoreArticles = async (
    requestBody: Record<string, unknown>,
    reset = false,
  ) => {
    if (isLoading) return;
    setIsLoading(true);

    const fromIndex = reset
      ? 0
      : initialArticles.length + currentPage * pageSize;

    try {
      const response = await fetchApi(endpoint, "POST", {
        ...requestBody,
        from: fromIndex,
      });
      const { results, totalResults } = response as MoreArticlesResponseBody;

      if (reset) {
        // Clear previous results
        setArticleIds(new Set(results.map((article) => article.id)));
        setMoreArticles([results]);
        setCurrentPage(1);
      } else {
        // Remove duplicates and append to previous results
        const filteredResults = results.filter(
          (article) => !articleIds.has(article.id),
        );
        const filteredIds = filteredResults.map((article) => article.id);
        setArticleIds((articleIds) => new Set([...articleIds, ...filteredIds]));
        setMoreArticles((moreArticles) => [...moreArticles, filteredResults]);
        setCurrentPage((currentPage) => currentPage + 1);
      }

      setTotalResults(totalResults);
      setHasMore(results.length >= pageSize);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      // Reset to empty state
      setMoreArticles([]);
      setArticleIds(new Set());
      setHasMore(true);
      setTotalResults(-1);
      setCurrentPage(0);
      setIsLoading(false);
    }
  };

  return {
    moreArticles,
    fetchMoreArticles,
    totalResults,
    hasMore,
    isLoading,
  };
}
