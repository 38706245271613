import KopitimeLogo from "@app/assets/kopitime.png";
import { TITLE_SPORTS } from "@app/config/constants";
import cx from "classnames";
import type { ReactElement } from "react";

export type PageHeaderProps = {
  title: string;
};

export default function PageHeader({ title }: PageHeaderProps): ReactElement {
  const isKopitime = title.toLowerCase() === "kopitime";
  const displayTitle = title.toLowerCase() === "sports" ? TITLE_SPORTS : title;

  return (
    <h1
      className={cx(
        "flex items-center justify-center gap-x-md font-secondary text-34 font-extrabold leading-1 text-primary-500",
        {
          "p-md": !isKopitime,
          "p-sm": isKopitime,
        },
      )}
    >
      {isKopitime && (
        <img
          className="h-[67px] w-[54px]"
          src={KopitimeLogo}
          alt="Kopitime logo"
        />
      )}
      {displayTitle}
    </h1>
  );
}
