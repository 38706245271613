import { isArrayWithElements } from "@app/helpers/utils";
import PageHeader from "@components/Elements/PageHeader/PageHeader";
import Attachment from "@elements/Attachment/Attachment";
import { ResponsePayload } from "@pages/Article/Article.server";
import BasePage from "@pages/BasePage";
import StatusHandler from "@pages/StatusHandler/StatusHandler";
import {
  CustomContext,
  ResponseType,
  TRouteWithoutRedirect,
  useRouteContext,
} from "@sphtech/web2-core/ssr";
import type { ReactElement } from "react";

export default function RaceCardsPage(): ReactElement {
  const routeContext: CustomContext<
    TRouteWithoutRedirect<ResponsePayload, string>
  > = useRouteContext();
  const dataLoaderResponse = routeContext.context;

  if (dataLoaderResponse.type !== ResponseType.SUCCESS) {
    return <StatusHandler response={dataLoaderResponse} />;
  }

  const { article } = dataLoaderResponse.payload;

  return (
    <BasePage>
      <section>
        <PageHeader title={article.title} />
        <div className="mb-lg flex flex-col gap-y-lg">
          {isArrayWithElements(article.attachments) && (
            <ul className="flex flex-row flex-wrap">
              {article.attachments.map((attachment, index) => (
                <li key={index} className="flex w-full grow-0 lg:basis-1/3">
                  <Attachment attachment={attachment} />
                </li>
              ))}
            </ul>
          )}
        </div>
      </section>
    </BasePage>
  );
}
