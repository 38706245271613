export const BUTTON_TITLES = {
  headerMenuOpenToggle: "Open menu",
  headerMenuCloseToggle: "Close menu",
  searchInputExpand: "Expand search input",
  searchInputCollapse: "Collapse search input",
  galleryOpen: "Open media gallery",
  galleryClose: "Close media gallery",
  galleryPrevious: "View previous media",
  galleryNext: "View next media",
  shareOpenToggle: "Show additional sharing options",
  shareCloseToggle: "Hide additional sharing options",
} as const;

export const HEADER_HEIGHT_SM = 68;
export const HEADER_HEIGHT_LG = 90;

export const PAGE_CACHE_CONTROL_MAX_AGE = 60;

export const CATEGORY_SPORTS = "sports";
export const RACE_CARDS_PATH: string = import.meta.env.VITE_RACE_CARDS_PATH;
export const RACE_CARDS_PINNED_POSITION: number = import.meta.env
  .VITE_RACE_CARDS_PINNED_POSITION;
export const TITLE_SPORTS: string = import.meta.env.VITE_SPORTS_TITLE;
