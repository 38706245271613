import "./index.css";

import { GTM_ID } from "@app/config/gadata";
import HeadScriptProvider from "@app/providers/HeadScriptProvider";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { useMySphLightbox } from "@sphtech/web2-core/auth";
import { RenderContext } from "@sphtech/web2-core/ssr";
import NeuronProvider from "@web2/providers/NeuronProvider";
import NewRelic from "@web2/providers/NewRelic";
import {
  getClientId,
  getLightboxUrl,
} from "@web2/web2-helpers/auth/MySphConfig";
import { type ReactElement, StrictMode, useContext } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Outlet, ScrollRestoration } from "react-router-dom";

export default function App(): ReactElement {
  const renderContext = useContext(RenderContext);

  /**
   * Integration guide: https://sph.atlassian.net/wiki/x/V4GqY
   */
  useMySphLightbox({
    lightboxUrl: getLightboxUrl(),
    clientId: getClientId(),
  });

  return (
    <StrictMode>
      <HeadScriptProvider />
      <NeuronProvider>
        <ScrollRestoration />
        <HelmetProvider context={renderContext.helmet}>
          <GTMProvider state={{ id: GTM_ID }}>
            <NewRelic />
            <Outlet />
          </GTMProvider>
        </HelmetProvider>
      </NeuronProvider>
    </StrictMode>
  );
}
