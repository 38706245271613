import type { ReactElement } from "react";

export type SelectProps = {
  label: string;
  options: { label: string; value: string }[];
  value?: string;
  onChange?(event: React.ChangeEvent<HTMLSelectElement>): void;
};

export default function Select({
  label,
  options,
  value,
  onChange,
}: SelectProps): ReactElement {
  return (
    <div className="flex items-center gap-x-sm font-secondary">
      <label className="text-14" htmlFor="search-sort">
        {label}
      </label>
      <select
        className="relative h-full border border-black p-sm text-16 leading-1.2"
        id="search-sort"
        value={value}
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
