export type SocialPlatform = "facebook" | "instagram" | "tiktok";

export const SOCIAL_LINKS: Record<
  SocialPlatform,
  {
    icon: "facebook-circle" | "instagram" | "tiktok";
    title: string;
    url: string;
  }
> = {
  facebook: {
    icon: "facebook-circle",
    title: "Visit our Facebook",
    url: "https://www.facebook.com/thenewpaper",
  },
  instagram: {
    icon: "instagram",
    title: "Visit our Instagram",
    url: "https://www.instagram.com/thenewpaper",
  },
  tiktok: {
    icon: "tiktok",
    title: "Visit our TikTok",
    url: "https://www.tiktok.com/@thenewpaperdigital",
  },
};
