import { create } from "zustand";

export interface AdVarStore {
  isPrestitialEnabled: boolean | undefined;
  setIsPrestitialEnabled: (isPrestitalEnabled: boolean) => void;
  topOverlayImpressions: number | undefined;
  setTopOverlayImpressions: (topOverlayImpressions: number) => void;
  isCatfishEnabled: boolean | undefined;
  setIsCatfishEnabled: (isCatfishEnabled: boolean) => void;
  isOutbrainEnabled: boolean | undefined;
  setIsOutbrainEnabled: (isOutbrainEnabled: boolean) => void;
}

const useAdVarStore = create<AdVarStore>((set) => ({
  isPrestitialEnabled: undefined,
  setIsPrestitialEnabled: (isPrestitialEnabled: boolean) => {
    set({ isPrestitialEnabled });
  },
  topOverlayImpressions: undefined,
  setTopOverlayImpressions: (topOverlayImpressions: number) => {
    set({ topOverlayImpressions });
  },
  isCatfishEnabled: undefined,
  setIsCatfishEnabled: (isCatfishEnabled: boolean) => {
    set({ isCatfishEnabled });
  },
  isOutbrainEnabled: undefined,
  setIsOutbrainEnabled: (isOutbrainEnabled: boolean) => {
    set({ isOutbrainEnabled });
  },
}));

export default useAdVarStore;
