import { ADS_DOMAIN, adSlotSizes } from "@app/config/ads";
import useAdVarStore from "@app/store/useAdVarStore";
import betterads from "@elements/Advertisement/scripts/betterads";
import betteradsHead from "@elements/Advertisement/scripts/betteradsHead";
import betteradsHead2 from "@elements/Advertisement/scripts/betteradsHead2";
import { useScript } from "@sphtech/web2-core/hooks";
import { AdTypeEnum } from "@typings/Ads";
import { useEffect } from "react";

export default function useLoadedTags() {
  const setIsPrestitialEnabled = useAdVarStore(
    (state) => state.setIsPrestitialEnabled,
  );
  const setTopOverlayImpressions = useAdVarStore(
    (state) => state.setTopOverlayImpressions,
  );
  const setIsCatfishEnabled = useAdVarStore(
    (state) => state.setIsCatfishEnabled,
  );
  const setIsOutbrainEnabled = useAdVarStore(
    (state) => state.setIsOutbrainEnabled,
  );

  useScript({
    id: "ads-checker-script",
    src: `${ADS_DOMAIN}/tag/ads/ads_checker.js`,
  });

  useScript({
    id: "suid-script",
    src: "https://cdn.sphlabs.com/suid/suid.min.js",
  });

  useEffect(() => {
    // Make sure that googletag.cmd exists.
    window.googletag = (window.googletag as typeof googletag | undefined) || {};
    ((window.googletag as typeof googletag).cmd as googletag.CommandArray) =
      ((window.googletag as typeof googletag).cmd as
        | googletag.CommandArray
        | undefined) || [];
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    window.googletag.slots = window.googletag.slots || {};

    // Correct: Queueing the callback on the command queue.
    googletag.cmd.push(function () {
      const dfpTagsObj = googletag
        .pubads()
        .getSlots()
        .map((_slot) => {
          const id = _slot.getSlotElementId().replaceAll("dfp-ad-", "");

          const size = Object.keys(
            adSlotSizes[id] as googletag.GeneralSize,
          ).map((_, index) => {
            const sizes = adSlotSizes[id][index];

            return sizes.toString().replaceAll(",", "x");
          });

          const loadedDfpTag = {
            [id]: {
              adunit: _slot.getAdUnitPath(),
              slotname: _slot.getSlotElementId(),
              size: size.join(","),
            },
          };

          // Added googletag.slots so ads team can feed from it.
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.googletag.slots[
            _slot.getSlotElementId().replaceAll("dfp-ad-", "")
          ] = _slot;

          return loadedDfpTag;
        });

      const loadedDfpTags: Partial<Record<AdTypeEnum, object>> = {};

      dfpTagsObj.forEach((item: Partial<Record<AdTypeEnum, object>>) => {
        const key = Object.keys(item).at(0) as AdTypeEnum | undefined;

        key && (loadedDfpTags[key] = item[key]);
      });

      if (Object.keys(loadedDfpTags).length > 0) {
        // Load dfpTagsScript.
        const loadDfpTagsScriptId = "loadDfpTagsScript";
        if (!document.getElementById(loadDfpTagsScriptId)) {
          const loadDfpTagsScript = document.createElement("script");

          loadDfpTagsScript.id = loadDfpTagsScriptId;
          loadDfpTagsScript.dataset.testid = loadDfpTagsScriptId;
          loadDfpTagsScript.innerHTML = `var loaded_dfp_tags = '${JSON.stringify(
            loadedDfpTags,
          )}';`;
          loadDfpTagsScript.type = "text/javascript";
          document.head.appendChild(loadDfpTagsScript);
        }

        // Load betteradsHeadScript.
        const loadBetteradsHeadScriptId = "betteradsHeadScript";
        if (!document.getElementById(loadBetteradsHeadScriptId)) {
          const loadBetteradsHeadScript = document.createElement("script");

          loadBetteradsHeadScript.id = loadBetteradsHeadScriptId;
          loadBetteradsHeadScript.dataset.testid = loadBetteradsHeadScriptId;
          loadBetteradsHeadScript.innerHTML = betteradsHead;
          loadBetteradsHeadScript.type = "text/javascript";
          document.head.appendChild(loadBetteradsHeadScript);
        }

        // Load prebid.js
        const loadPrebidScriptId = "prebidScript";
        if (!document.getElementById(loadPrebidScriptId)) {
          const loadPrebidScript = document.createElement("script");

          loadPrebidScript.id = loadPrebidScriptId;
          loadPrebidScript.dataset.testid = loadPrebidScriptId;
          loadPrebidScript.src = `${ADS_DOMAIN}/tag/smx/sites/common/prebid.js`;
          loadPrebidScript.type = "text/javascript";
          document.head.appendChild(loadPrebidScript);
        }

        // Load smx_prebid.js
        const loadSmxPrebidScriptId = "loadSmxPrebidScript";
        if (!document.getElementById(loadSmxPrebidScriptId)) {
          const loadSmxPrebidScript = document.createElement("script");

          loadSmxPrebidScript.id = loadSmxPrebidScriptId;
          loadSmxPrebidScript.dataset.testid = loadSmxPrebidScriptId;
          loadSmxPrebidScript.src = `${ADS_DOMAIN}/tag/smx/sites/common/smx_prebid.js`;
          loadSmxPrebidScript.onload = () => {
            if (typeof window === "undefined") return;
            setIsPrestitialEnabled(window.prestitial_enabled === 1);
            setTopOverlayImpressions(window.topOverlayImpressions as number);
            setIsCatfishEnabled(window.catfish_enabled === 1);
            setIsOutbrainEnabled(window.outbrain_enable === 1);

            // Load betteradsHead2 when smx_prebid is loaded.
            const loadBetteradsHead2ScriptId = "betteradsHead2Script";
            if (!document.getElementById(loadBetteradsHead2ScriptId)) {
              const loadBetteradsHead2Script = document.createElement("script");

              loadBetteradsHead2Script.id = loadBetteradsHead2ScriptId;
              loadBetteradsHead2Script.dataset.testid =
                loadBetteradsHead2ScriptId;
              loadBetteradsHead2Script.innerHTML = betteradsHead2;
              loadBetteradsHead2Script.type = "text/javascript";
              document.body.appendChild(loadBetteradsHead2Script);
            }
          };
          document.head.appendChild(loadSmxPrebidScript);
        }

        // Load betteradsScript.
        const loadBetteradsScriptId = "betteradsScript";
        if (!document.getElementById(loadBetteradsScriptId)) {
          const loadBetteradsScript = document.createElement("script");

          loadBetteradsScript.id = loadBetteradsScriptId;
          loadBetteradsScript.dataset.testid = loadBetteradsScriptId;
          loadBetteradsScript.innerHTML = betterads;
          loadBetteradsScript.type = "text/javascript";
          document.head.appendChild(loadBetteradsScript);
        }
      }
    });
  }, [
    setIsPrestitialEnabled,
    setTopOverlayImpressions,
    setIsCatfishEnabled,
    setIsOutbrainEnabled,
  ]);
}
