import { getErrorMessage } from "./errors";
import { getWindowOrigin } from "./utils";

export async function fetchApi<T>(
  endpoint: string,
  method: "GET" | "POST",
  requestData?: unknown,
): Promise<T> {
  try {
    const url = `${getWindowOrigin()}/_plat/api/v1/${endpoint}`;
    const options: RequestInit = {
      method: method,
      headers: {
        "Content-Type": "application/json",
      },
      body: method === "POST" ? JSON.stringify(requestData) : undefined,
    };

    const response = await fetch(url, options);
    if (!response.ok) {
      throw new Error(`HTTP error on fetch ${url}! Status: ${response.status}`);
    }

    return response.json() as Promise<T>;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    console.error(`Error occurred when fetching ${endpoint}:`, errorMessage);

    throw error;
  }
}
