import IframeAttr from "@components/Elements/Embeds/helper/IframeAttr";
import SocialEmbedFactory from "@components/Elements/Embeds/helper/SocialEmbedFactory";
import Iframe from "@elements/Iframe/Iframe";
import { isThisUrlFrom } from "@helpers/utils";

export default function Embed({
  platform,
  url,
  className,
}: {
  platform: string;
  url: string;
  className: string;
}): React.ReactElement {
  if (!isThisUrlFrom(url, platform)) {
    platform = "unknown";
  } else if (platform === "facebook") {
    const parsedUrl = new URL(url);
    const isUsePlugin = parsedUrl.pathname.includes("/plugins/");

    if (isUsePlugin) {
      const params = new URLSearchParams(parsedUrl.search);
      const href = params.get("href") || "";
      if (href) {
        url = decodeURIComponent(href);
      } else {
        platform = "facebook-plugin"; //for other type of FB embed  will use iFrame
      }
    }
  }

  const SocialEmbed = SocialEmbedFactory(platform);
  const iframeAttr = IframeAttr(url, className);

  return (
    <div className={className} data-testid="embed-component">
      {SocialEmbed ? (
        <SocialEmbed
          url={platform === "youtube" ? `${url}?enablejsapi=true` : url}
          className={className}
        />
      ) : (
        <Iframe {...iframeAttr} />
      )}
    </div>
  );
}
