import { getWindowOrigin } from "@app/helpers/utils";

type SharingMethod =
  | "copy"
  | "email"
  | "facebook"
  | "facebook-messenger"
  | "linkedin"
  | "twitter"
  | "whatsapp";
type ReactionEmoji =
  | "emoji-red-heart"
  | "emoji-laughing"
  | "emoji-mouth-open"
  | "emoji-cry"
  | "emoji-rage";

// NOTE: order of items is important here
export const SHARE_BUTTON_CONFIG: Record<
  SharingMethod,
  {
    name: string;
    icon: string;
    title: string;
    ga: string;
    getUrl: (url: string, title?: string) => string;
  }
> = {
  whatsapp: {
    name: "WhatsApp",
    icon: "whatsapp",
    title: "Share on WhatsApp",
    ga: "whatsapp",
    getUrl: (url, title = "") =>
      `https://api.whatsapp.com/send?text=${encodeURIComponent(title + " - ")}${getWindowOrigin() + url}`,
  },
  facebook: {
    name: "Facebook",
    icon: "facebook",
    title: "Share on Facebook",
    ga: "fb",
    getUrl: (url) =>
      `https://www.facebook.com/sharer/sharer.php?u=${getWindowOrigin() + url}&src=sdkpreparse`,
  },
  linkedin: {
    name: "LinkedIn",
    icon: "linkedin",
    title: "Share on LinkedIn",
    ga: "linkedin",
    getUrl: (url) =>
      `https://www.linkedin.com/feed/?linkOrigin=LI_BADGE&shareActive=true&shareUrl=${getWindowOrigin() + url}`,
  },
  ["facebook-messenger"]: {
    name: "FB Messenger",
    icon: "facebook-messenger",
    title: "Share on FB Messenger",
    ga: "fb messenger",
    getUrl: (url) =>
      `https://www.facebook.com/dialog/send?_path=send&app_id=2927136990683378&client_id=2927136990683378&redirect_uri=${getWindowOrigin() + url}&display=popup&link=${getWindowOrigin() + url}`,
  },
  twitter: {
    name: "Twitter / X",
    icon: "twitter",
    title: "Share on Twitter",
    ga: "twitter",
    getUrl: (url, title = "") =>
      `https://x.com/intent/post?url=${getWindowOrigin() + url}&text=${encodeURIComponent(title)}&hashtags=readOnTNP`,
  },
  email: {
    name: "Email",
    icon: "email",
    title: "E-mail this article",
    ga: "email",
    getUrl: (url, title = "") =>
      `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(title + "\n\n")}${getWindowOrigin() + url}`,
  },
  copy: {
    name: "Copy Permalink",
    icon: "hyperlink",
    title: "Copy this article's permalink",
    ga: "copy permalink",
    getUrl: (url) => getWindowOrigin() + url,
  },
};

export const REACTION_CONFIG: Record<
  ReactionEmoji,
  {
    imageUrl: string;
    imageAlt: string;
  }
> = {
  "emoji-red-heart": {
    imageUrl: "/reactions/emoji-red-heart.gif",
    imageAlt: "Red Heart emoji",
  },
  "emoji-laughing": {
    imageUrl: "/reactions/emoji-laughing.gif",
    imageAlt: "Laughing emoji",
  },
  "emoji-mouth-open": {
    imageUrl: "/reactions/emoji-mouth-open.gif",
    imageAlt: "Mouth Open emoji",
  },
  "emoji-cry": {
    imageUrl: "/reactions/emoji-cry.gif",
    imageAlt: "Cry emoji",
  },
  "emoji-rage": {
    imageUrl: "/reactions/emoji-rage.gif",
    imageAlt: "Rage emoji",
  },
};
