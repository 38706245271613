import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { PRD_BASE_HOST } from "@helpers/getEnvVariables";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function SearchMetaData(
  searchedText: string,
): PageMetaDataValues {
  const sectionName = "miscellaneous";
  const metaTitle = `Site Search For "${searchedText}" - The New Paper`;
  const metaDescription = `The New Paper - Get breaking news, latest Sports, Entertainment & Lifestyle News from Singapore, Asia and around the world at ${PRD_BASE_HOST}`;

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug: `/sitesearch/?key=${searchedText}`,
    robotsContent: `noindex, nofollow`,
  };

  const gaData: GADataProps = {
    level2: sectionName,
    section: sectionName,
    contentcat: "1",
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
