import { ARTICLE_COUNT } from "@app/config/article";
import { SEARCH_SORT_DEFAULT, SEARCH_SORT_OPT } from "@app/config/search";
import { isArrayWithElements } from "@app/helpers/utils";
import { useLoadMore } from "@app/hooks/useLoadMore";
import SearchInput from "@components/Blocks/SearchInput/SearchInput";
import ArticleList from "@components/Layouts/ArticleList/ArticleList";
import Advertisement from "@elements/Advertisement/Advertisement";
import Button from "@elements/Button/Button";
import Select from "@elements/Select/Select";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { getWindowHref } from "@helpers/utils";
import BasePage, { type BasePageProps } from "@pages/BasePage";
import { type ReactElement, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import SearchMetaData from "./SearchMetaData";

export default function SearchPage(): ReactElement {
  const [initialFetch, setInitialFetch] = useState(true);
  const [params, setSearchParam] = useSearchParams();
  const keyParam = decodeURIComponent(params.get("key") ?? "");
  const sortParam = decodeURIComponent(params.get("sort") ?? "");
  const [query, setQuery] = useState(keyParam);
  const [sortBy, setSortBy] = useState(sortParam || SEARCH_SORT_DEFAULT);

  const { gaData, metaTags } = SearchMetaData(keyParam);
  const pageName = "search";
  const basePageProps: BasePageProps = {
    gaData,
    metaTags: metaTags,
    pageName: pageName,
  };

  const { moreArticles, fetchMoreArticles, totalResults, hasMore, isLoading } =
    useLoadMore({
      endpoint: "more-search-articles",
      pageSize: ARTICLE_COUNT.search.results,
      initialArticles: [],
      totalArticles: 0,
    });

  const handleSearchReset = () => {
    setQuery("");
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
  };

  const handleSearch = () => {
    setSearchParam({ key: query, sort: sortBy });
    fetchMoreArticles(
      {
        keyword: query,
        size: ARTICLE_COUNT.search.results,
        sortBy,
      },
      true,
    )
      .then(() => {})
      .catch(() => {});
  };

  const handleSortByChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newSortBy = event.target.value;
    setSortBy(newSortBy);
    setSearchParam({ key: query, sort: newSortBy });
    fetchMoreArticles(
      {
        keyword: query,
        size: ARTICLE_COUNT.search.results,
        sortBy: newSortBy,
      },
      true,
    )
      .then(() => {})
      .catch(() => {});
  };

  const sendGaEvent = useGTMDispatch();
  const handleLoadMore = () => {
    if (!initialFetch) {
      sendGaEvent({
        event: "custom_event",
        clickCategory: "load more",
        clickAction: "click",
        clickLabel: getWindowHref(),
      });
    }

    fetchMoreArticles({
      keyword: query,
      size: ARTICLE_COUNT.search.results,
      sortBy,
    })
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    if (initialFetch) {
      setSearchParam({ key: query, sort: sortBy });
      handleLoadMore();
      setInitialFetch(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialFetch, query, sortBy]);

  return (
    <BasePage {...basePageProps}>
      <section className="flex flex-col lg:gap-lg">
        <div className="flex flex-col lg:flex-row xl:gap-lg">
          <div className="flex flex-col gap-y-lg lg:basis-3/4">
            <div className="flex flex-col gap-y-lg p-md">
              {totalResults === 0 && (
                <div className="flex flex-col gap-y-lg font-secondary leading-1 text-primary-500">
                  <h2 className="text-26 font-extrabold lg:text-34">
                    No stories found for your search.
                  </h2>
                  <h3 className="text-20 font-bold lg:text-24">
                    Please try a different keyword.
                  </h3>
                </div>
              )}
              <SearchInput
                variant="page"
                placeholder="Search for"
                value={query}
                onChange={handleSearchChange}
                onReset={handleSearchReset}
                onPressEnter={handleSearch}
                preventDefaultSubmit
              />
              <div className="flex flex-row-reverse justify-between">
                <Select
                  label="Sort by:"
                  options={SEARCH_SORT_OPT}
                  value={sortBy}
                  onChange={handleSortByChange}
                />
                {totalResults > 0 && (
                  <h2 className="my-auto flex items-baseline font-secondary leading-1 text-primary-500">
                    <span className="text-26 font-extrabold lg:text-34">
                      {`${totalResults}`}
                    </span>
                    &nbsp;&nbsp;
                    <span className="text-20 font-bold lg:text-34 lg:font-extrabold">
                      Results
                    </span>
                  </h2>
                )}
              </div>
            </div>
            {moreArticles.length > 0 && (
              <>
                <ArticleList variant="search" articles={moreArticles[0]} />
                <Advertisement
                  variant="bn1"
                  pageName={pageName}
                  pageNumber={0}
                  isCompanionAds
                />
              </>
            )}
          </div>
          <aside className="mx-sm flex min-w-[300px] justify-center overflow-clip bg-grayscale-100 py-md lg:basis-1/4 lg:bg-white lg:py-0">
            <Advertisement variant="imu2" pageName={pageName} isSticky />
          </aside>
        </div>
        {moreArticles.slice(1).map((articles, index) => (
          <div
            key={`article-group-${index}`}
            className="flex flex-col lg:flex-row lg:gap-y-lg"
          >
            <div className="flex flex-col gap-y-lg lg:basis-3/4">
              {articles.length > 0 && (
                <>
                  <ArticleList variant="search" articles={articles} />
                  <Advertisement
                    variant="bn1"
                    pageName={pageName}
                    pageNumber={index + 1}
                    isCompanionAds
                  />
                </>
              )}
            </div>
            <aside className="mx-sm flex min-w-[300px] justify-center overflow-clip bg-grayscale-100 py-md lg:basis-1/4 lg:bg-white lg:py-0">
              <Advertisement
                variant="imu2"
                pageName={pageName}
                pageNumber={index + 1}
                isSticky
                isCompanionAds
              />
            </aside>
          </div>
        ))}
        <div className="my-md flex items-center justify-center">
          {totalResults > 0 &&
            isArrayWithElements(moreArticles) &&
            (hasMore ? (
              <Button variant="primary" onClick={handleLoadMore}>
                {isLoading ? "Loading..." : "Read More Stories"}
              </Button>
            ) : (
              <span className="font-secondary text-24 font-bold leading-1.2">
                You have reached the end.
              </span>
            ))}
        </div>
      </section>
    </BasePage>
  );
}
