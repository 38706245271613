import { adSizeMappings, adSlotSizes, adTargetings } from "@app/config/ads";
import { AdTargetingType, AdUnitProps } from "@typings/Ads";
import { useCallback, useEffect, useState } from "react";

import { generateAdvertElementId } from "./helpers";

/**
 * This component renders an IMU ad.
 * @param parentClassName - The parent class name.
 * @param className - The class name.
 * @param type - The type of ad unit.
 * @param slot - The ad slot.
 * @param id - The id of the ad.
 * @see https://developers.google.com/doubleclick-gpt/reference
 * @returns A IMU ad component. If the script is not ready, it returns error. If the script is ready, it returns the ad.
 */
export const AdUnit = ({
  parentClassName = "",
  className = "",
  type,
  slot = "",
  pageNumber = 0,
  id = generateAdvertElementId(type, pageNumber),
  isCompanionAds = false,
}: AdUnitProps) => {
  const [error, setError] = useState<string>("");
  const [adSlot, setAdSlot] =
    useState<ReturnType<typeof googletag.defineSlot>>();
  const adSlotSize = adSlotSizes[type] as googletag.GeneralSize | undefined;
  const adSizeMapping = adSizeMappings[type];
  let adTarget;
  if (isCompanionAds) {
    adTarget = [
      { key: "pos", value: `${pageNumber}` },
      { key: "page_number", value: `${pageNumber}` },
    ];
  } else {
    adTarget = adTargetings[type];
  }

  /**
   * This function is called when the Google script is loaded.
   * It defines the ad slot and loads the ad.
   * @returns void
   * @see https://developers.google.com/doubleclick-gpt/reference#googletag.cmd.push
   */
  const handleDisplayAd = useCallback(() => {
    try {
      // Make sure that googletag.cmd exists.
      window.googletag =
        (window.googletag as typeof googletag | undefined) || {};
      ((window.googletag as typeof googletag).cmd as googletag.CommandArray) =
        ((window.googletag as typeof googletag).cmd as
          | googletag.CommandArray
          | undefined) || [];
      // Correct: Queueing the callback on the command queue.
      googletag.cmd.push(function () {
        // Remove any existing ad slots with the same id.
        googletag
          .pubads()
          .getSlots()
          .forEach(function (_slot: googletag.Slot) {
            if (_slot.getSlotElementId() && _slot.getSlotElementId() === id) {
              googletag.destroySlots([_slot]);
            }
          });
        // Create the ad slot.
        if (undefined != adSlotSize) {
          const _adSlot = googletag.defineSlot(slot, adSlotSize, id) as
            | googletag.Slot
            | undefined;

          if (undefined != _adSlot) {
            if (adSizeMapping) _adSlot.defineSizeMapping(adSizeMapping);

            adTarget?.forEach((target: AdTargetingType) => {
              _adSlot.setTargeting(target.key, target.value);
            });

            _adSlot.addService(googletag.pubads());

            if (isCompanionAds) {
              googletag
                .pubads()
                .refresh([_adSlot], { changeCorrelator: false });
            } else {
              googletag.pubads().enableSingleRequest();
              googletag.enableServices();
            }

            // Store the ad slot for later use.
            setAdSlot(_adSlot);
          }
        }
      });
    } catch (_error: unknown) {
      setError("Please refresh");
    }
  }, [slot, id, isCompanionAds, adSlotSize, adSizeMapping, adTarget]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      if (slot && !adSlot) {
        handleDisplayAd();
      }
    }
  }, [slot, handleDisplayAd, adSlot]);

  useEffect(() => {
    /**
     * This function destroys the ad slot when the component is unmounted.
     * @returns void
     * @see https://developers.google.com/doubleclick-gpt/reference#googletag.destroySlots
     */
    return () => {
      const gTag = window.googletag as typeof googletag | undefined;
      if (gTag && gTag.apiReady && adSlot) {
        googletag.cmd.push(function () {
          googletag.destroySlots([adSlot]);
        });
      }
    };
  }, [adSlot]);

  return (
    <div className={parentClassName}>
      {!error ? (
        <div data-testid={id} id={id} className={className}></div>
      ) : (
        error
      )}
    </div>
  );
};
