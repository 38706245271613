import { ADS_PREFIX } from "@app/config/ads";
import { TNP_BASE_URL } from "@app/helpers/getEnvVariables";
import { removeTrailingSlash } from "@app/helpers/utils";
import { AdTypeEnum } from "@typings/Ads";

export const generateAdvertElementId = (
  type: AdTypeEnum,
  pageNumber?: number,
) => {
  let id = `dfp-ad-${type}`;
  if (pageNumber && pageNumber > 0) {
    id = `${id}-${pageNumber}`;
  }
  return id;
};

export const adslotName = (variant: AdTypeEnum, pageName?: string) => {
  let adslotName = `${ADS_PREFIX}/${variant}`;
  if (null != pageName) {
    adslotName = `${adslotName}/${pageName}`;
  }
  return adslotName;
};

export const adTarget = (pageName: string) => {
  let value = pageName;
  if (pageName == "homepage") {
    value = "home";
  }
  return [{ key: "page", value: value }];
};

export async function getIas(pagePath: string): Promise<string> {
  const iasUrl = (import.meta.env.VITE_IAS_API_URL as string) || "";

  const pageUrl = encodeURIComponent(
    `${removeTrailingSlash(TNP_BASE_URL)}${pagePath}`,
  );
  const iasPageUrl = `${iasUrl}${pageUrl}`;

  let ias = "";
  try {
    const response = await fetch(iasPageUrl, {
      signal: AbortSignal.timeout(5000),
      method: "GET",
    });
    const result = (await response.json()) as Partial<{
      channels: { name: string }[];
    }>;
    const value = (result.channels || []).map((i) => i.name).join(",");

    ias = value;
    if (["no_analysis", "no_category"].includes(value)) {
      ias = "";
    }
  } catch (e: unknown) {
    console.error(e);
  }

  return ias;
}
