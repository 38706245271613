import { AUTHOR_BYLINE_IMAGE } from "@app/config/imageresponsive";
import InternalLink from "@elements/InternalLink/InternalLink";
import { Image } from "@elements/ResponsiveImage/Image";
import IcArrowRight from "@icons/arrow-right.svg";
import IcEmail from "@icons/email.svg";
import type { ArticleByline, ArticleBylineBasic } from "@typings/OpenSearch";
import cx from "classnames";
import type { ReactElement } from "react";
import { Link } from "react-router-dom";

export type BylineProps = {
  byline: Partial<ArticleByline>;
};

function BasicByline({
  byline,
  hasByPrefix,
  hasMultiple,
}: {
  byline: ArticleBylineBasic;
  hasByPrefix: boolean;
  hasMultiple: boolean;
}): ReactElement {
  const classNames = cx("flex text-16 font-medium leading-1.5", {
    "flex-col": !hasMultiple,
    "flex-col gap-x-sm md:flex-row": hasMultiple,
  });

  return (
    <div className="flex gap-x-sm">
      {byline.photo && (
        <div className="shrink-0">
          <Image
            className="size-[52px] rounded-full object-cover"
            src={byline.photo}
            alt={byline.name}
            width={AUTHOR_BYLINE_IMAGE.width}
            height={AUTHOR_BYLINE_IMAGE.height}
            loading="eager"
          />
        </div>
      )}
      <div className={classNames}>
        <div className="flex grow flex-wrap items-center gap-x-xs">
          {hasByPrefix && <span>By</span>}
          {byline.email ? (
            <Link
              className="flex items-center gap-x-xs text-primary-500 hover:underline"
              to={`mailto:${byline.email}`}
            >
              {byline.name}
              <IcEmail className="size-icon-md" />
            </Link>
          ) : (
            <span>{`${byline.name}${byline.designation ? "," : ""}`}</span>
          )}
          {byline.designation && <span>{byline.designation}</span>}
        </div>
        <InternalLink
          className="flex grow flex-wrap items-center gap-x-xs text-primary-500 hover:underline"
          to={byline.urlPath}
        >
          {`Read articles by ${byline.name}`}
          <IcArrowRight className="size-icon-md" />
        </InternalLink>
      </div>
    </div>
  );
}

export default function Byline({ byline }: BylineProps): ReactElement | null {
  if (Array.isArray(byline.basicByline) && byline.basicByline.length > 0) {
    return byline.basicByline.length === 1 ? (
      <BasicByline
        byline={byline.basicByline[0]}
        hasByPrefix={true}
        hasMultiple={false}
      />
    ) : (
      <ul className="flex flex-col gap-y-sm">
        {byline.basicByline.map((bl, index) => (
          <li key={`byline-${index}`}>
            <BasicByline
              byline={bl}
              hasByPrefix={index === 0}
              hasMultiple={true}
            />
          </li>
        ))}
      </ul>
    );
  }

  if (byline.customByline) {
    return (
      <div
        className="text-16 font-medium leading-1.5"
        dangerouslySetInnerHTML={{
          __html: byline.customByline,
        }}
      />
    );
  }

  return null;
}
