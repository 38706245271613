import { useLoadedAd } from "@app/hooks/useLoadedAd";
import { AdUnit } from "@elements/Advertisement/AdUnit";
import { adslotName } from "@elements/Advertisement/helpers";
import { AdTypeEnum } from "@typings/Ads";
import cx from "classnames";
import type { ReactElement } from "react";

export type AdvertisementProps = {
  rootClassName?: string;
  variant: AdTypeEnum;
  isSticky?: boolean;
  pageName?: string;
  id?: string;
  pageNumber?: number;
  isCompanionAds?: boolean;
};

export default function Advertisement({
  rootClassName,
  variant,
  isSticky,
  pageName,
  id,
  pageNumber,
  isCompanionAds,
}: AdvertisementProps): ReactElement {
  const isAdLoaded = useLoadedAd({ type: variant, pageNumber });

  const parentClassNames = cx(
    rootClassName,
    "flex h-fit justify-center text-center",
    {
      "min-h-[250px] w-[300px]": ["imu2", "imu3"].includes(variant),
      "!block w-full clear-both relative [text-align:-webkit-center] min-h-[425px]":
        ["imu1", "midarticlespecial"].includes(variant),
      "mx-auto w-[320px] items-center lg:min-h-[120px] lg:w-[970px]":
        variant === "lb1",
      "mx-auto items-center w-dvw h-[106px] bg-gray-100 lg:min-h-[120px] p-sm":
        variant === "lb2",
      "lg:sticky lg:top-[--header-height-lg]": isSticky,
    },
    !isAdLoaded && variant != "lb1" && "!hidden invisible",
  );

  const classNames = cx({
    "sticky top-0": ["imu1", "midarticlespecial"].includes(variant),
  });

  return (
    <AdUnit
      type={variant}
      slot={adslotName(variant, pageName)}
      id={id}
      pageNumber={pageNumber}
      isCompanionAds={isCompanionAds}
      parentClassName={parentClassNames}
      className={classNames}
    />
  );
}
