import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { PRD_BASE_HOST } from "@helpers/getEnvVariables";
import type { PageMetaDataValues } from "@typings/PageMetaData";

export default function HomeMetaData(section: string): PageMetaDataValues {
  const metaTags: MetaTagsProps = {
    title:
      "The New Paper - Breaking News, Sports, Entertainment & Lifestyle News",
    description: `The New Paper - Get breaking news, latest Sports, Entertainment & Lifestyle News from Singapore, Asia and around the world at ${PRD_BASE_HOST}`,
    slug: "",
  };

  const gaData: GADataProps = {
    level2: section,
    title: "TNP_Homepage",
    section: section,
    contentcat: "1",
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
