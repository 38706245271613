import { adSlotSizes, adTargetings } from "@app/config/ads";
import { useLoadedAd } from "@app/hooks/useLoadedAd";
import { AdTypeEnum } from "@typings/Ads";
import cx from "classnames";
import Cookies, { CookieAttributes } from "js-cookie";
import { useCallback, useEffect, useState } from "react";

import { generateAdvertElementId } from "./helpers";

const TYPE: AdTypeEnum = "catfish";
const ID = generateAdvertElementId(TYPE);
const CATFISH_VALIDITY = 4;

export type CatfishAdProps = {
  rootClassName?: string;
  slot: string;
  isCatfishEnabled: boolean | undefined;
};

export default function CatfishAd({
  rootClassName,
  slot,
  isCatfishEnabled,
}: CatfishAdProps) {
  const catfishDisplayedCookie = Cookies.get("catfishDisplayed");

  const isAdLoaded = useLoadedAd({ type: TYPE, id: ID });
  const [error, setError] = useState("");
  const [adSlot, setAdSlot] =
    useState<ReturnType<typeof googletag.defineSlot>>();
  const adSlotSize = adSlotSizes[TYPE];
  const adTarget = adTargetings[TYPE];

  const handleDisplayAd = useCallback(() => {
    try {
      // Make sure that googletag.cmd exists.
      window.googletag =
        (window.googletag as typeof googletag | undefined) || {};
      ((window.googletag as typeof googletag).cmd as googletag.CommandArray) =
        ((window.googletag as typeof googletag).cmd as
          | googletag.CommandArray
          | undefined) || [];
      // Correct: Queueing the callback on the command queue.
      googletag.cmd.push(function () {
        // Remove any existing ad slots with the same id.
        googletag
          .pubads()
          .getSlots()
          .forEach(function (_slot: googletag.Slot) {
            if (_slot.getSlotElementId() && _slot.getSlotElementId() === ID) {
              googletag.destroySlots([_slot]);
            }
          });

        // Create the ad slot.
        const _adSlot = googletag.defineSlot(slot, adSlotSize, ID) as
          | googletag.Slot
          | undefined;

        if (undefined != _adSlot) {
          _adSlot.addService(googletag.pubads());

          adTarget?.forEach(({ key, value }) => {
            _adSlot.setTargeting(key, value);
          });

          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          window.googletag.slots[TYPE] = _adSlot;

          googletag.pubads().enableSingleRequest();
          googletag.enableServices();

          // Store the ad slot for later use.
          setAdSlot(_adSlot);
        }
      });
    } catch (_error: unknown) {
      setError("Please refresh");
    }
  }, [slot, adSlotSize, adTarget]);

  useEffect(() => {
    if (typeof window !== "undefined" && isCatfishEnabled != undefined) {
      if (slot && !adSlot) {
        const isDfpTest = window.location.search.includes("dfp_preview");
        // window.dfp_preview_ids != "" &&
        // window.dfp_preview_ids != undefined &&
        // // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        // window.dfp_preview_ids?.length != 0;
        if (
          (typeof catfishDisplayedCookie === "undefined" || isDfpTest) &&
          screen.width <= 767 &&
          isCatfishEnabled
        ) {
          handleDisplayAd();

          const expiry = new Date();
          expiry.setTime(expiry.getTime() + CATFISH_VALIDITY * 60 * 60 * 1000);
          const cookieOptions: CookieAttributes = {
            path: "/",
            expires: expiry,
          };

          Cookies.set("catfishDisplayed", "yes", cookieOptions);
        }
      }
    }
  }, [slot, handleDisplayAd, adSlot, catfishDisplayedCookie, isCatfishEnabled]);

  useEffect(() => {
    /**
     * This function destroys the ad slot when the component is unmounted.
     * @returns void
     * @see https://developers.google.com/doubleclick-gpt/reference#googletag.destroySlots
     */
    return () => {
      const gTag = window.googletag as typeof googletag | undefined;
      if (gTag && gTag.apiReady && adSlot) {
        googletag.cmd.push(function () {
          googletag.destroySlots([adSlot]);
        });
      }
    };
  }, [adSlot]);

  return (
    <>
      <div className={cx(rootClassName, !isAdLoaded && "invisible hidden")}>
        {!error ? <div data-testid={ID} id={ID}></div> : error}
      </div>
    </>
  );
}
