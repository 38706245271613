import type { PageMetaDataValues } from "@app/types/PageMetaData";
import type { GADataProps } from "@elements/GAData/GAData";
import type { MetaTagsProps } from "@elements/MetaTags/MetaTags";
import { PRD_BASE_HOST } from "@helpers/getEnvVariables";
import { convertKebabToSentence } from "@helpers/utils";

export type SectionMetaDataProps = {
  sectionName: string;
  parentSection: string | undefined;
};

export function SectionMetaData({
  sectionName,
  parentSection,
}: SectionMetaDataProps): PageMetaDataValues {
  let seoDescription, capitalizeSectionName, capitalizeParentName;

  capitalizeSectionName = convertKebabToSentence(sectionName);
  capitalizeParentName = convertKebabToSentence(parentSection || sectionName);

  switch (sectionName) {
    case "news":
      capitalizeSectionName = capitalizeParentName = "";
      seoDescription =
        "News - Get latest Singapore, Asia, South East Asia and World news & headlines today. Find top stories published today";
      break;
    case "sports":
      seoDescription =
        "News - Get latest sport news today includes football, tennis, formula 1, basketball, golf, school sports and more sports news";
      break;
    case "entertainment":
      seoDescription =
        "Entertainment News - Get latest news includes Music, TV, Star Style, School of Frock & Movie News and more entertainment news";
      break;
    case "lifestyle":
      seoDescription =
        "Lifestyle News - Get latest Lifestyle news & top stories today includes Makan, Health, Tech, Travel, Fashion & Shopping news";
      break;
    default:
      seoDescription = `${capitalizeSectionName} News - Get latest ${capitalizeSectionName} news. Find top stories published today`;
      break;
  }

  const parentSectionName =
    capitalizeParentName !== capitalizeSectionName
      ? `${capitalizeParentName} - ${capitalizeSectionName}`
      : capitalizeSectionName;

  const metaTitle = `Latest ${parentSectionName} News, Stories & Headlines Today - The New Paper`;
  const metaDescription: string | undefined =
    `The New Paper ${seoDescription} at ${PRD_BASE_HOST}`;

  const metaTags: MetaTagsProps = {
    title: metaTitle,
    description: metaDescription,
    slug:
      parentSection !== sectionName
        ? `${parentSection}/${sectionName}`
        : sectionName,
  };

  const level2 = parentSection
    ? parentSection.replace("-", " ")
    : sectionName.replace("-", " ");

  const gaData: GADataProps = {
    level2: level2,
    title: `${capitalizeSectionName}_Index`,
    section: level2,
    chapter1: parentSection && capitalizeSectionName,
    contentcat: "1",
    contenttype: "listing",
  };

  return {
    gaData,
    metaTags,
  };
}
